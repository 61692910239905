import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import StickyNav from './StickyNav';
import TopNav from './TopNav';
import './Notifications.css';

function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationType, setNotificationType] = useState('');
  const [notificationCount, setNotificationCount] = useState(0);
  const navigate = useNavigate();
  const tg = window.Telegram.WebApp;
  const notificationsRef = useRef(null);

  useEffect(() => {
    tg.ready();
    tg.expand();
    loadNotifications();

    ensureDocumentIsScrollable();
    preventCollapse();
    document.addEventListener('touchstart', preventCollapse);

    document.addEventListener('DOMContentLoaded', () => {
      tg.expand();
      tg.onEvent('viewportChanged', () => tg.expand());

      document.body.addEventListener(
        'touchmove',
        (event) => {
          if (!event.target.closest('#content')) {
            event.preventDefault();
          }
        },
        { passive: false }
      );
    });

    return () => {
      document.removeEventListener('touchstart', preventCollapse);
      document.body.removeEventListener(
        'touchmove',
        (event) => {
          if (!event.target.closest('#content')) {
            event.preventDefault();
          }
        },
        { passive: false }
      );
    };
  }, []);

  const ensureDocumentIsScrollable = () => {
    const isScrollable = document.documentElement.scrollHeight > window.innerHeight;
    if (!isScrollable) {
      document.documentElement.style.setProperty('height', 'calc(100vh + 1px)', 'important');
    }
  };

  const preventCollapse = () => {
    if (window.scrollY === 0) {
      window.scrollTo(0, 1);
    }
  };

  const loadNotifications = async () => {
    const tg = window.Telegram.WebApp;
    const user_id = tg.initDataUnsafe.user.id;

    try {
      const notifications = await fetch(`/load_notifications?user_id=${user_id}`).then((res) => res.json());

      // Сортировка уведомлений по времени создания
      notifications.sort((a, b) => b.timestamp - a.timestamp);

      setNotifications(notifications);
      setNotificationCount(notifications.length);
    } catch (error) {
      console.error('Ошибка загрузки уведомлений:', error);
    }
  };

  const handleFriendRequest = async (requestId, action) => {
    const endpoint = action === 'accept' ? '/accept_friend_request' : '/decline_friend_request';

    try {
      await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ request_id: requestId }),
      });
      showNotification(
        `Запрос на добавление в друзья ${
          action === 'accept' ? 'принят' : 'отклонен'
        }`,
        action === 'accept' ? 'accept' : 'decline'
      );
      loadNotifications();
    } catch (error) {
      console.error(
        `Ошибка при ${action} запросе на добавление в друзья:`,
        error
      );
    }
  };

  const showNotification = (message, type) => {
    setNotificationMessage(message);
    setNotificationType(type);
    setTimeout(() => {
      setNotificationMessage('');
    }, 3000);
  };

  const handleUsernameClick = (userId) => {
    if (userId) {
      navigate(`/profile?user_id=${userId}`);
    }
  };

  return (
    <div id="content">
      <TopNav notificationCount={notificationCount} />
      <div id="notifications" ref={notificationsRef}>
        {notifications.length === 0 ? (
          <div className="no-notifications">Уведомлений нет</div>
        ) : (
          notifications.map((notification) => (
            <div key={notification.id} className="notification-item">
              {notification.sender_id && (
                <div
                  className="notification-username"
                  onClick={() => handleUsernameClick(notification.sender_id)}
                >
                  {notification.sender_username}
                </div>
              )}
              <div className="notification-text">{notification.text}</div>
              {notification.type === 'friend_request' && (
                <div className="button-container">
                  <button
                    className="accept-button"
                    onClick={() =>
                      handleFriendRequest(notification.id.split('-')[1], 'accept')
                    }
                  >
                    Принять
                  </button>
                  <button
                    className="decline-button"
                    onClick={() =>
                      handleFriendRequest(notification.id.split('-')[1], 'decline')
                    }
                  >
                    Отклонить
                  </button>
                </div>
              )}
            </div>
          ))
        )}
      </div>
      <div id="notification" className={`${notificationType} ${notificationMessage ? 'show' : ''}`}>
        {notificationMessage}
      </div>
      <StickyNav />
    </div>
  );
}

export default Notifications;
