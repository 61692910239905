import React from "react";
import { FaStar } from "react-icons/fa";
import "./BattleTeam.css";

const BattleTeam = ({
  battleTeam,
  onSlotClick,
  selectedPlayerCardIndex,
  attackingCardIndex,
  attackedCardIndex,
  readOnly = false,
  pveMode = false,
  isPlayerTurn,
  movePosition,
}) => {
  // Функция для определения цвета тени в зависимости от количества звёзд
  const getBoxShadowColor = (stars) => {
    switch (stars) {
      case 1:
        return "0 0 10px lightgray"; // 1 звезда - светло-серый цвет
      case 2:
        return "0 0 13px green"; // 2 звезды - зелёный цвет
      case 3:
        return "0 0 15px blue"; // 3 звезды - синий цвет
      case 4:
        return "0 0 17px purple"; // 4 звезды - фиолетовый цвет
      case 5:
         return "0 0 20px rgba(255, 0, 0, 0.8), 0 0 25px rgba(255, 165, 0, 0.6), 0 0 30px rgba(255, 255, 0, 0.4)"; // 5 звезд - огненный градиент
      default:
        return "0 0 10px transparent"; // Для карт без звёзд
    }
  };

  return (
    <div className="battle-team-container">
      <div className="slots-container">
        {battleTeam.map((card, index) => {
          const hpPercentage = card ? (card.hp / card.maxHp) * 100 : 0;

          return (
            <div
              key={index}
              className={`slot ${card ? "filled" : ""} ${
                index === selectedPlayerCardIndex ? "selected" : ""
              } ${index === attackingCardIndex && isPlayerTurn ? "attacking" : ""} ${
                index === attackedCardIndex && !isPlayerTurn ? "shaking" : ""
              }`}
              onClick={() => onSlotClick(index)}
              style={
                index === attackingCardIndex
                  ? {
                      "--move-x": `${movePosition.x}px`,
                      "--move-y": `${movePosition.y}px`,
                      "--hp-percentage": `${hpPercentage}%`,
                    }
                  : { "--hp-percentage": `${hpPercentage}%` }
              }
            >
              {card && (
                <>
                  <div className="small-stars">
                    {Array.from({ length: card.stars }).map((_, starIndex) => (
                      <FaStar key={starIndex} className="small-star-icon" color="#ffd700" />
                    ))}
                  </div>
                  <img
                    src={card.image_url}
                    alt={`Card ${index}`}
                    style={{ boxShadow: getBoxShadowColor(card.stars) }}
                  />
                  {pveMode && <div className="card-hp">HP: {card.hp}</div>}
                  <div className="card-level-indicator">{card.current_level}</div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BattleTeam;
