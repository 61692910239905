import { useEffect } from 'react';

function usePreventCollapse() {
  useEffect(() => {
    const tg = window.Telegram.WebApp;

    const preventCollapse = () => {
      if (window.scrollY === 0) {
        window.scrollTo(0, 1);
      }
    };

    const ensureDocumentIsScrollable = () => {
      const isScrollable = document.documentElement.scrollHeight > window.innerHeight;
      if (!isScrollable) {
        document.documentElement.style.setProperty("height", "calc(100vh + 1px)", "important");
      }
    };

    const handleTouchMove = (event) => {
      if (!event.target.closest('#content')) {
        event.preventDefault();
      }
    };

    tg.ready();
    tg.expand();

    ensureDocumentIsScrollable();
    preventCollapse();
    document.addEventListener('touchstart', preventCollapse);
    document.addEventListener('DOMContentLoaded', () => {
      tg.expand();
      tg.onEvent('viewportChanged', () => tg.expand());
      document.body.addEventListener('touchmove', handleTouchMove, { passive: false });
    });

    return () => {
      document.removeEventListener('touchstart', preventCollapse);
      document.body.removeEventListener('touchmove', handleTouchMove, { passive: false });
    };
  }, []);
}

export default usePreventCollapse;
