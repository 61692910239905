import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './Game.css';

const MAX_BULLETS = 10;
const MAX_METEORS = 20;
const METEOR_SPAWN_INTERVAL = 1000;
const BULLET_SPEED = 0.3;
const BOOSTED_BULLET_SPEED = BULLET_SPEED * 2;
const METEOR_SPEED = 0.1;
const BOOST_DURATION = 15000;
const BOOST_SPAWN_INTERVAL = 15000;
const NORMAL_BULLET_INTERVAL = 300;
const BOOSTED_BULLET_INTERVAL = 150;
const BOOST_HP = 5;

const MIN_ENEMY_SHIP_SPAWN_INTERVAL = 30000;
const MAX_ENEMY_SHIP_SPAWN_INTERVAL = 120000;
const ENEMY_BULLET_SPEED = 0.2;
const ENEMY_BULLET_INTERVAL = 500;
const ENEMY_SHIP_SPEED = 0.1;
const ENEMY_SHIP_HP = 20;
const ENEMY_SHIP_SCORE = 100;

const MAX_METEOR_HP = 20;
const MAX_METEOR_SIZE = 100;
const MIN_METEOR_SIZE = 35;

const SHIP_BASE_WIDTH = 50;
const SHIP_BASE_HEIGHT = 75;
const HEALTH_BAR_HEIGHT = 5;

const ENEMY_SHIP_WIDTH = 60; // Ширина вражеского корабля
const ENEMY_SHIP_HEIGHT = 60; // Высота вражеского корабля

const BOOST_IMAGE_SIZE = 30; // Размер изображения буста
const PART_SIZE = 20; // Размер детали корабля
const TOTAL_PARTS = 6; // Общее количество типов деталей

const Game = () => {
  const [shipPosition, setShipPosition] = useState(window.innerWidth / 2);
  const [enemyShip, setEnemyShip] = useState(null);
  const [enemyBullets, setEnemyBullets] = useState([]);
  const [meteors, setMeteors] = useState([]);
  const [bullets, setBullets] = useState([]);
  const [boosts, setBoosts] = useState([]);
  const [fragments, setFragments] = useState([]);
  const [parts, setParts] = useState([]);
  const [shipHp, setShipHp] = useState(100);
  const [score, setScore] = useState(0);
  const [bulletSpeed, setBulletSpeed] = useState(BULLET_SPEED);
  const [bulletInterval, setBulletInterval] = useState(NORMAL_BULLET_INTERVAL);
  const [bulletColor, setBulletColor] = useState('yellow');
  const [shipLevel, setShipLevel] = useState(null); // Изначально shipLevel не определен
  const [loading, setLoading] = useState(true); // Состояние загрузки данных
  const [shipImage, setShipImage] = useState(null); // Изображение корабля в зависимости от уровня

  const canvasRef = useRef(null);
  const shipImageRef = useRef(new Image());
  const meteorImageRef = useRef(new Image());
  const enemyShipImageRef = useRef(new Image());
  const boostImageRef = useRef(new Image());

  const lastTimeRef = useRef(0);
  const requestRef = useRef();
  const lastBulletTimeRef = useRef(0);
  const lastEnemyBulletTimeRef = useRef(0);
  const meteorCountRef = useRef(0);
  const boostTimeoutRef = useRef(null);
  const wsRef = useRef(null);
  const enemySpawnTimeoutRef = useRef(null);
  const navigate = useNavigate();

  const tg = window.Telegram.WebApp;
  const userId = tg.initDataUnsafe.user.id;

  useEffect(() => {
    // Загружаем информацию о корабле пользователя
    const loadShipInfo = async () => {
      try {
        const response = await fetch(`/api/user/${userId}/ship_info`);
        const data = await response.json();
        setShipLevel(data.shipLevel || 1);
        setLoading(false); // Устанавливаем состояние загрузки в false после получения данных
      } catch (error) {
        console.error('Ошибка загрузки информации о корабле:', error);
        setLoading(false); // Устанавливаем состояние загрузки в false при ошибке
      }
    };

    loadShipInfo();

    // Подключение WebSocket для работы с очками
    const ws = new WebSocket('wss://insectwar.ru');
    wsRef.current = ws;

    ws.onopen = () => {
      console.log('WebSocket-соединение установлено');
      ws.send(JSON.stringify({ type: 'load_score', user_id: userId }));
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.user_id === userId) {
        setScore(data.score);
      }
    };

    ws.onclose = () => {
      console.log('WebSocket-соединение закрыто');
    };

    return () => {
      if (ws) ws.close();
    };
  }, [userId]);

  useEffect(() => {
    if (shipLevel !== null) { // Убедитесь, что shipLevel загружен перед установкой изображений
      // Загрузка изображений на основе уровня корабля
      shipImageRef.current.src = `/images/cosmo/ship_${shipLevel}.png`;
      meteorImageRef.current.src = '/images/cosmo/meteor.png';
      enemyShipImageRef.current.src = '/images/cosmo/enemy-ship.png';
      boostImageRef.current.src = '/images/cosmo/boost.png';

      // Устанавливаем изображение корабля
      setShipImage(shipImageRef.current.src);
    }
  }, [shipLevel]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const dpr = window.devicePixelRatio || 1;

    // Установка физического размера canvas с учетом устройства
    canvas.width = window.innerWidth * dpr;
    canvas.height = window.innerHeight * dpr;
    ctx.scale(dpr, dpr);

    canvas.style.width = `${window.innerWidth}px`;
    canvas.style.height = `${window.innerHeight}px`;
  }, []);

  const handleTouchMove = useCallback((event) => {
    event.preventDefault();
    setShipPosition(event.touches[0].clientX);
  }, []);

  const activateBoost = useCallback(() => {
    setBulletSpeed(BOOSTED_BULLET_SPEED);
    setBulletInterval(BOOSTED_BULLET_INTERVAL);
    setBulletColor('blue');

    if (boostTimeoutRef.current) clearTimeout(boostTimeoutRef.current);

    boostTimeoutRef.current = setTimeout(() => {
      setBulletSpeed(BULLET_SPEED);
      setBulletInterval(NORMAL_BULLET_INTERVAL);
      setBulletColor('yellow');
      boostTimeoutRef.current = null;
    }, BOOST_DURATION);
  }, []);

  const drawShip = (ctx) => {
    if (shipImageRef.current.complete) {
      ctx.drawImage(
        shipImageRef.current,
        shipPosition - SHIP_BASE_WIDTH / 2,
        window.innerHeight - SHIP_BASE_HEIGHT - HEALTH_BAR_HEIGHT - 10,
        SHIP_BASE_WIDTH,
        SHIP_BASE_HEIGHT
      );

      // Рисуем полоску здоровья ниже корабля
      ctx.fillStyle = 'red';
      ctx.fillRect(
        shipPosition - SHIP_BASE_WIDTH / 2,
        window.innerHeight - HEALTH_BAR_HEIGHT - 5,
        (shipHp / 100) * SHIP_BASE_WIDTH,
        HEALTH_BAR_HEIGHT
      );
    }
  };

  const drawEnemyShip = (ctx) => {
    if (enemyShip) {
      ctx.drawImage(
        enemyShipImageRef.current,
        enemyShip.x - ENEMY_SHIP_WIDTH / 2,
        enemyShip.y,
        ENEMY_SHIP_WIDTH,
        ENEMY_SHIP_HEIGHT
      );

      // Рисуем полоску здоровья над вражеским кораблем
      ctx.fillStyle = 'red';
      ctx.fillRect(
        enemyShip.x - ENEMY_SHIP_WIDTH / 2,
        enemyShip.y - 10,
        (enemyShip.hp / ENEMY_SHIP_HP) * ENEMY_SHIP_WIDTH,
        5
      );
    }
  };

  const drawMeteor = (ctx, meteor) => {
    const meteorSize = Math.max(
      MIN_METEOR_SIZE,
      MIN_METEOR_SIZE + ((meteor.hp - 1) / (MAX_METEOR_HP - 1)) * (MAX_METEOR_SIZE - MIN_METEOR_SIZE)
    );
    ctx.drawImage(
      meteorImageRef.current,
      meteor.x - meteorSize / 2,
      meteor.y - meteorSize / 2,
      meteorSize,
      meteorSize
    );

    ctx.fillStyle = 'cyan';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(meteor.hp, meteor.x, meteor.y);
  };

  const drawFragment = (ctx, fragment) => {
    ctx.fillStyle = 'gray';
    ctx.beginPath();
    ctx.arc(fragment.x, fragment.y, 3, 0, Math.PI * 2);
    ctx.fill();
  };

  const drawBoost = (ctx, boost) => {
    ctx.drawImage(
      boostImageRef.current,
      boost.x - BOOST_IMAGE_SIZE / 2,
      boost.y - BOOST_IMAGE_SIZE / 2,
      BOOST_IMAGE_SIZE,
      BOOST_IMAGE_SIZE
    );

    const radius = BOOST_IMAGE_SIZE / 2 + 5;
    const startAngle = -Math.PI / 2;
    const endAngle = startAngle + (2 * Math.PI * boost.hp) / BOOST_HP;

    ctx.strokeStyle = 'orange';
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.arc(boost.x, boost.y, radius, startAngle, endAngle);
    ctx.stroke();
  };

  const drawBullet = (ctx, bullet) => {
    ctx.fillStyle = bulletColor;
    ctx.fillRect(bullet.x - 2.5, bullet.y, 5, 10);
  };

  const drawEnemyBullet = (ctx, bullet) => {
    ctx.fillStyle = 'red';
    ctx.fillRect(bullet.x - 2.5, bullet.y, 5, 10);
  };

  const drawPart = (ctx, part) => {
    ctx.fillStyle = 'lime';
    ctx.fillRect(part.x - PART_SIZE / 2, part.y - PART_SIZE / 2, PART_SIZE, PART_SIZE);
  };

  const clearCanvas = (ctx) => {
    ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
  };

  const createFragments = (x, y, count) => {
    const newFragments = Array.from({ length: count }, () => ({
      id: Date.now() + Math.random(),
      x,
      y,
      angle: Math.random() * 360,
      speed: Math.random() * 0.15 + 0.05,
    }));
    setFragments((prev) => [...prev, ...newFragments]);
  };

  const createPartDrop = (x, y) => {
    const partType = Math.floor(Math.random() * TOTAL_PARTS) + 1; // Выбор случайной детали от 1 до 6
    const newPart = {
      id: Date.now(),
      x,
      y,
      level: 1,
      type: partType,
    };
    setParts((prevParts) => [...prevParts, newPart]);
  };

  useEffect(() => {
    const addMeteor = () => {
      if (!enemyShip && meteorCountRef.current < MAX_METEORS) {
        const initialHp = Math.floor(Math.random() * MAX_METEOR_HP) + 1;
        const newMeteor = {
          id: Date.now(),
          x: Math.random() * window.innerWidth,
          y: 0,
          hp: initialHp,
          initialHp: initialHp,
        };
        setMeteors((prev) => [...prev, newMeteor]);
        meteorCountRef.current += 1;
      }
    };

    const meteorInterval = setInterval(addMeteor, METEOR_SPAWN_INTERVAL);
    return () => clearInterval(meteorInterval);
  }, [enemyShip]);

  useEffect(() => {
    const addBoost = () => {
      const newBoost = {
        id: Date.now(),
        x: Math.random() * window.innerWidth,
        y: 0,
        hp: BOOST_HP,
      };
      setBoosts((prev) => [...prev, newBoost]);
    };

    const boostInterval = setInterval(addBoost, BOOST_SPAWN_INTERVAL);
    return () => clearInterval(boostInterval);
  }, []);

  const scheduleNextEnemyShip = useCallback(() => {
    const spawnInterval =
      Math.random() * (MAX_ENEMY_SHIP_SPAWN_INTERVAL - MIN_ENEMY_SHIP_SPAWN_INTERVAL) +
      MIN_ENEMY_SHIP_SPAWN_INTERVAL;
    enemySpawnTimeoutRef.current = setTimeout(() => {
      setEnemyShip({
        x: Math.random() * window.innerWidth,
        y: 50,
        direction: Math.random() > 0.5 ? 1 : -1,
        hp: ENEMY_SHIP_HP,
      });

      meteorCountRef.current = 0;
    }, spawnInterval);
  }, []);

  useEffect(() => {
    if (!enemyShip) {
      scheduleNextEnemyShip();
    }

    return () => clearTimeout(enemySpawnTimeoutRef.current);
  }, [enemyShip, scheduleNextEnemyShip]);

  const updateGame = useCallback(
    (time) => {
      if (loading) return; // Пропускаем обновление игры, если данные еще загружаются

      const ctx = canvasRef.current.getContext('2d');
      clearCanvas(ctx);

      if (!lastTimeRef.current) lastTimeRef.current = time;
      const deltaTime = time - lastTimeRef.current;

      setMeteors((prevMeteors) =>
        prevMeteors
          .map((meteor) => ({ ...meteor, y: meteor.y + deltaTime * METEOR_SPEED }))
          .filter((meteor) => {
            if (meteor.y >= window.innerHeight * 0.9) {
              meteorCountRef.current -= 1;
              return false;
            }
            return true;
          })
      );

      setFragments((prevFragments) =>
        prevFragments.map((fragment) => ({
          ...fragment,
          x: fragment.x + Math.cos((fragment.angle * Math.PI) / 180) * deltaTime * fragment.speed,
          y: fragment.y + Math.sin((fragment.angle * Math.PI) / 180) * deltaTime * fragment.speed,
        }))
      );

      setBoosts((prevBoosts) =>
        prevBoosts
          .map((boost) => ({ ...boost, y: boost.y + deltaTime * METEOR_SPEED }))
          .filter((boost) => boost.y < window.innerHeight * 0.9)
      );

      setParts((prevParts) =>
        prevParts.map((part) => ({ ...part, y: part.y + deltaTime * METEOR_SPEED }))
      );

      setBullets((prevBullets) =>
        prevBullets
          .map((bullet) => ({ ...bullet, y: bullet.y - deltaTime * bulletSpeed }))
          .filter((bullet) => bullet.y > 0)
      );

      if (enemyShip) {
        setEnemyShip((prev) => {
          if (!prev) return null;
          const newX = prev.x + prev.direction * deltaTime * ENEMY_SHIP_SPEED;
          if (newX <= 0 || newX >= window.innerWidth) {
            return { ...prev, direction: -prev.direction };
          }
          return { ...prev, x: newX };
        });

        if (time - lastEnemyBulletTimeRef.current > ENEMY_BULLET_INTERVAL) {
          const newBullet = {
            id: Date.now(),
            x: enemyShip.x,
            y: enemyShip.y + ENEMY_SHIP_HEIGHT,
          };
          setEnemyBullets((prev) => [...prev, newBullet]);
          lastEnemyBulletTimeRef.current = time;
        }

        setBullets((prevBullets) => {
          const newBullets = prevBullets.filter((bullet) => {
            if (
              enemyShip &&
              bullet.x > enemyShip.x - ENEMY_SHIP_WIDTH / 2 &&
              bullet.x < enemyShip.x + ENEMY_SHIP_WIDTH / 2 &&
              bullet.y > enemyShip.y &&
              bullet.y < enemyShip.y + ENEMY_SHIP_HEIGHT
            ) {
              setEnemyShip((prev) => {
                if (!prev) return null;
                if (prev.hp - 1 <= 0) {
                  setScore((prevScore) => prevScore + ENEMY_SHIP_SCORE);
                  if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                    wsRef.current.send(
                      JSON.stringify({
                        type: 'increase_score',
                        user_id: userId,
                        increment: ENEMY_SHIP_SCORE,
                      })
                    );
                  }
                  setEnemyBullets([]);
                  scheduleNextEnemyShip();
                  createPartDrop(enemyShip.x, enemyShip.y); // Дроп случайной детали при уничтожении врага
                  return null;
                }
                return { ...prev, hp: prev.hp - 1 };
              });
              return false;
            }
            return true;
          });
          return newBullets;
        });
      }

      setEnemyBullets((prevBullets) =>
        prevBullets
          .map((bullet) => ({ ...bullet, y: bullet.y + deltaTime * ENEMY_BULLET_SPEED }))
          .filter((bullet) => bullet.y < window.innerHeight)
      );

      setEnemyBullets((prevBullets) => {
        const shipRect = {
          left: shipPosition - SHIP_BASE_WIDTH / 2,
          right: shipPosition + SHIP_BASE_WIDTH / 2,
          top: window.innerHeight - SHIP_BASE_HEIGHT - HEALTH_BAR_HEIGHT - 10,
          bottom: window.innerHeight - HEALTH_BAR_HEIGHT - 5,
        };
        const newBullets = prevBullets.filter((bullet) => {
          if (
            bullet.x > shipRect.left &&
            bullet.x < shipRect.right &&
            bullet.y > shipRect.top &&
            bullet.y < shipRect.bottom
          ) {
            setShipHp((prevHp) => Math.max(prevHp - 10, 0));

            if (shipHp - 10 <= 0) {
              navigate('/');
              setShipHp(100);
              setMeteors([]);
              setBullets([]);
              setEnemyBullets([]);
              setEnemyShip(null);
              meteorCountRef.current = 0;
            }
            return false;
          }
          return true;
        });

        return newBullets;
      });

      setBoosts((prevBoosts) => {
        const newBoosts = prevBoosts
          .map((boost) => {
            const collidedBulletIndex = bullets.findIndex(
              (bullet) =>
                Math.abs(bullet.x - boost.x) < 20 && Math.abs(bullet.y - boost.y) < 20
            );

            if (collidedBulletIndex !== -1) {
              const updatedHp = boost.hp - 1;

              setBullets((prevBullets) =>
                prevBullets.filter((_, index) => index !== collidedBulletIndex)
              );

              if (updatedHp <= 0) {
                activateBoost();
                return null;
              }

              return { ...boost, hp: updatedHp };
            }

            return boost;
          })
          .filter((boost) => boost !== null);

        return newBoosts;
      });

      setMeteors((prevMeteors) => {
        const newMeteors = prevMeteors.map((meteor) => {
          const meteorSize = Math.max(
            MIN_METEOR_SIZE,
            MIN_METEOR_SIZE + ((meteor.hp - 1) / (MAX_METEOR_HP - 1)) * (MAX_METEOR_SIZE - MIN_METEOR_SIZE)
          );
          const collidedBulletIndex = bullets.findIndex(
            (bullet) =>
              bullet.x > meteor.x - meteorSize / 2 &&
              bullet.x < meteor.x + meteorSize / 2 &&
              bullet.y > meteor.y - meteorSize / 2 &&
              bullet.y < meteor.y + meteorSize / 2
          );

          if (collidedBulletIndex !== -1) {
            const updatedHp = meteor.hp - 1;

            setBullets((prevBullets) =>
              prevBullets.filter((_, index) => index !== collidedBulletIndex)
            );

            if (updatedHp > 0) {
              createFragments(meteor.x, meteor.y, 1);
            } else {
              createFragments(meteor.x, meteor.y, 6);
              setScore((prevScore) => prevScore + meteor.initialHp);
              if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                wsRef.current.send(
                  JSON.stringify({
                    type: 'increase_score',
                    user_id: userId,
                    increment: meteor.initialHp,
                  })
                );
              }
              createPartDrop(meteor.x, meteor.y); // Дроп случайной детали при уничтожении метеорита
              return null;
            }

            return { ...meteor, hp: updatedHp };
          }

          return meteor;
        });

        return newMeteors.filter((meteor) => meteor !== null);
      });

      setMeteors((prevMeteors) => {
        const newMeteors = prevMeteors.filter((meteor) => {
          const meteorSize = Math.max(
            MIN_METEOR_SIZE,
            MIN_METEOR_SIZE + ((meteor.hp - 1) / (MAX_METEOR_HP - 1)) * (MAX_METEOR_SIZE - MIN_METEOR_SIZE)
          );
          const shipRect = {
            left: shipPosition - SHIP_BASE_WIDTH / 2,
            right: shipPosition + SHIP_BASE_WIDTH / 2,
            top: window.innerHeight - SHIP_BASE_HEIGHT - HEALTH_BAR_HEIGHT - 10,
            bottom: window.innerHeight - HEALTH_BAR_HEIGHT - 5,
          };

          const meteorRect = {
            left: meteor.x - meteorSize / 2,
            right: meteor.x + meteorSize / 2,
            top: meteor.y - meteorSize / 2,
            bottom: meteor.y + meteorSize / 2,
          };

          if (
            meteorRect.right > shipRect.left &&
            meteorRect.left < shipRect.right &&
            meteorRect.bottom > shipRect.top &&
            meteorRect.top < shipRect.bottom
          ) {
            setShipHp((prevHp) => {
              const newHp = Math.max(prevHp - meteor.hp, 0);

              if (newHp <= 0) {
                navigate('/');
                setShipHp(100);
                setMeteors([]);
                setBullets([]);
                setEnemyBullets([]);
                setEnemyShip(null);
                meteorCountRef.current = 0;
              }

              return newHp;
            });

            meteorCountRef.current -= 1;
            return false;
          }

          return true;
        });

        return newMeteors;
      });

      setParts((prevParts) => {
        const shipRect = {
          left: shipPosition - SHIP_BASE_WIDTH / 2,
          right: shipPosition + SHIP_BASE_WIDTH / 2,
          top: window.innerHeight - SHIP_BASE_HEIGHT - HEALTH_BAR_HEIGHT - 10,
          bottom: window.innerHeight - HEALTH_BAR_HEIGHT - 5,
        };

        return prevParts.filter((part) => {
          const partRect = {
            left: part.x - PART_SIZE / 2,
            right: part.x + PART_SIZE / 2,
            top: part.y - PART_SIZE / 2,
            bottom: part.y + PART_SIZE / 2,
          };

          if (
            partRect.right > shipRect.left &&
            partRect.left < shipRect.right &&
            partRect.bottom > shipRect.top &&
            partRect.top < shipRect.bottom
          ) {
            // Обработка сбора детали
            fetch('/api/user/add_part', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                userId,
                partName: `Деталь уровня ${part.level}`,
                partLevel: part.level,
                image: `/images/parts/part_${part.type}.png`, // Уникальное изображение для каждой детали
                type: part.type,
              }),
            }).catch((error) => console.error('Ошибка добавления детали:', error));
            return false;
          }
          return true;
        });
      });

      drawShip(ctx);
      drawEnemyShip(ctx);
      meteors.forEach((meteor) => drawMeteor(ctx, meteor));
      bullets.forEach((bullet) => drawBullet(ctx, bullet));
      boosts.forEach((boost) => drawBoost(ctx, boost));
      enemyBullets.forEach((bullet) => drawEnemyBullet(ctx, bullet));
      fragments.forEach((fragment) => drawFragment(ctx, fragment));
      parts.forEach((part) => drawPart(ctx, part));

      if (time - lastBulletTimeRef.current > bulletInterval && bullets.length < MAX_BULLETS) {
        const newBullet = {
          id: Date.now(),
          x: shipPosition,
          y: window.innerHeight - SHIP_BASE_HEIGHT - HEALTH_BAR_HEIGHT - 20,
        };
        setBullets((prev) => [...prev, newBullet]);
        lastBulletTimeRef.current = time;
      }

      lastTimeRef.current = time;
      requestRef.current = requestAnimationFrame(updateGame);
    },
    [
      shipPosition,
      meteors,
      bullets,
      boosts,
      enemyBullets,
      fragments,
      parts,
      bulletColor,
      enemyShip,
      shipHp,
      userId,
      navigate,
      loading, // Добавляем loading в зависимости
    ]
  );

  useEffect(() => {
    if (!loading) {
      requestRef.current = requestAnimationFrame(updateGame);
    }
    return () => cancelAnimationFrame(requestRef.current);
  }, [updateGame, loading]);

  return (
    <div className="game-container" onTouchMove={handleTouchMove}>
      <canvas ref={canvasRef} width={window.innerWidth} height={window.innerHeight} />
      <div id="scoreContainer">
        <p>
          Очки: <span id="score">{score}</span>
        </p>
      </div>
    </div>
  );
};

export default Game;
