import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileShip from './ProfileShip'; 
import Shop from './Shop'; // Импортируем компонент магазина
import { FaStore } from 'react-icons/fa'; // Импорт иконки магазина
import './Home.css';
import StickyNav from './StickyNav'; 
import { FaBolt, FaGasPump, FaTrophy } from 'react-icons/fa'; 
import { GiCrystalGrowth } from 'react-icons/gi'; 
import LeaderboardModal from './LeaderboardModal'; 

function Home() {
  const [score, setScore] = useState(0);
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [isGameStarted, setIsGameStarted] = useState(false);
  const [isShipClicked, setIsShipClicked] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showShop, setShowShop] = useState(false); // Для управления отображением магазина
  const [playerCards, setPlayerCards] = useState([]); // Для хранения карточек игрока

  const [profile, setProfile] = useState({
    username: 'Имя пользователя',
    profileImage: 'https://via.placeholder.com/100',
  });
  const [shipLevel, setShipLevel] = useState(null);
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [loadingShip, setLoadingShip] = useState(true);

  const [energy, setEnergy] = useState(50);
  const [fuel, setFuel] = useState(50);

  const navigate = useNavigate();
  const tg = window.Telegram.WebApp;
  const userId = tg.initDataUnsafe?.user?.id;

  useEffect(() => {
    if (userId) {
      tg.ready();
      tg.expand();
      document.body.style.overflow = 'hidden';
  
      const username = tg.initDataUnsafe.user?.username;

      if (username) {
        setProfile((prevProfile) => ({
          ...prevProfile,
          username: username,
        }));
      }

      loadProfile(userId); 
      loadShipInfo(userId); 
      loadUserResources(userId); 
      loadScore(userId); 
      loadPlayerCards(userId); // Загрузка карточек
      createStars();
    }
  }, [userId]);

  const createStars = () => {
    const starfield = document.getElementById('starfield');
    const starCount = 100;

    for (let i = 0; i < starCount; i++) {
      const star = document.createElement('div');
      star.className = 'star';
      star.style.top = `${Math.random() * 100}%`;
      star.style.left = `${Math.random() * 100}%`;
      star.style.animationDelay = `${Math.random() * 5}s`;
      starfield.appendChild(star);
    }
  };

  const loadProfile = async (userId) => {
    try {
      const response = await fetch(`/load_user_profile?user_id=${userId}`);
      const data = await response.json();

      setProfile((prevProfile) => ({
        ...prevProfile,
        username: data.username || prevProfile.username,
        profileImage: data.profileImage ? `${data.profileImage}?t=${new Date().getTime()}` : 'https://via.placeholder.com/100',
      }));
    } catch (error) {
      console.error('Ошибка загрузки профиля пользователя:', error);
    } finally {
      setLoadingProfile(false);
    }
  };

  const loadShipInfo = async (userId) => {
    try {
      const response = await fetch(`/api/user/${userId}/ship_info`);
      const data = await response.json();

      setShipLevel(data.shipLevel || 1);
    } catch (error) {
      console.error('Ошибка загрузки информации о корабле:', error);
    } finally {
      setLoadingShip(false);
    }
  };

  const loadUserResources = async (userId) => {
    try {
      const response = await fetch(`/api/user/${userId}/resources`);
      const data = await response.json();

      setEnergy(data.energy);
      setFuel(data.fuel);
    } catch (error) {
      console.error('Ошибка загрузки ресурсов пользователя:', error);
    }
  };

  const loadScore = async (userId) => {
    try {
      const response = await fetch(`/load_score`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: userId }),
      });
      const data = await response.json();

      setScore(data.score || 0);
    } catch (error) {
      console.error('Ошибка загрузки счёта:', error);
    }
  };

  const loadPlayerCards = async (userId) => {
    try {
      const response = await fetch(`/api/user/${userId}/cards`);
      const cards = await response.json();
      setPlayerCards(cards);
    } catch (error) {
      console.error('Ошибка загрузки карточек игрока:', error);
    }
  };

  const handleProfileClick = () => {
    navigate(`/profile?user_id=${userId}`);
  };

  const handleLeaderboardClick = () => {
    setShowLeaderboard(true); // Открываем модальное окно
  };

  const closeLeaderboard = () => {
    setShowLeaderboard(false); // Закрываем модальное окно
  };

  const handleShopClick = () => {
    setShowShop(true); // Открываем магазин
  };

  const closeShop = () => {
    setShowShop(false); // Закрываем магазин
  };

  const startGame = () => {
    if (fuel > 0) {
      setIsShipClicked(true);
      fetch(`/api/user/${userId}/start_game`, {
        method: 'POST',
      })
        .then(() => {
          setFuel(fuel - 1);
        })
        .catch((error) => console.error('Ошибка начала игры:', error));
    } else {
      alert('Недостаточно топлива для начала игры!');
    }
  };

  const handleAnimationEnd = () => {
    navigate('/game');
  };

  const handleUpgradeClick = () => {
    setShowUpgradeModal(true);
  };

  const handleUpgradeClose = () => {
    setShowUpgradeModal(false);
  };

  return (
    <div id="content">
      <div id="starfield"></div>
      <div id="profileContainer">
        <div id="profileDisplay" onClick={handleProfileClick}>
          <div className="username-wrapper">
            <div className="username" id="displayUsername">{profile.username}</div>
          </div>

          <div className="profile-content">
            <div className="profile-left">
              {loadingProfile ? (
                <div>Loading...</div>
              ) : (
                <img id="displayImage" src={profile.profileImage} alt="Фотография профиля" />
              )}
            </div>

            <div id="resourceDisplay" className="profile-right">
              <div className="score-display">
                <GiCrystalGrowth className="score-icon" />
                <span>{score}</span>
              </div>

              <div className="bars">
                <div className="bar energy-bar">
                  <FaBolt className="bar-icon" />
                  <div className="bar-fill">
                    <div className="bar-fill-inner" style={{ width: `${(energy / 50) * 100}%` }}></div>
                  </div>
                </div>
                <div className="bar fuel-bar">
                  <FaGasPump className="bar-icon" />
                  <div className="bar-fill">
                    <div className="bar-fill-inner" style={{ width: `${(fuel / 50) * 100}%` }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     <div id="iconContainer">
  {/* Лидерборд иконка */}
  <button className="icon-button" onClick={handleLeaderboardClick}>
    <FaTrophy className="icon" />
  </button>

  {/* Магазин иконка */}
  <button className="icon-button" onClick={handleShopClick}>
    <FaStore className="icon" />
  </button>
</div>
      </div>

      <LeaderboardModal show={showLeaderboard} onClose={closeLeaderboard} currentUserId={userId} />

      <Shop 
        show={showShop} 
        onClose={closeShop} 
        userScore={score} 
        userId={userId}  // Передаем userId в компонент Shop
        loadScore={loadScore}
        loadPlayerCards={loadPlayerCards}
      />

      <div id="game">
        {!isGameStarted && !loadingShip && (
          <div
            id="startShip"
            onClick={startGame}
            className={isShipClicked ? 'ship-animation' : ''}
            onAnimationEnd={handleAnimationEnd}
          >
            <img
              src={`/images/cosmo/ship_${shipLevel}.png`}
              alt="Корабль"
              width={50}
              height={75}
              style={{ transform: 'rotate(0deg)' }}
            />
          </div>
        )}
        {!isGameStarted && !loadingShip && (
          <button className="upgrade-button cyberpunk-button" onClick={handleUpgradeClick}>
            Улучшить корабль
          </button>
        )}
      </div>

      {showUpgradeModal && (
        <ProfileShip 
          onClose={handleUpgradeClose} 
          shipLevel={shipLevel} 
          setShipLevel={setShipLevel} 
          userId={userId}
        />
      )}

      <StickyNav />
    </div>
  );
}

export default Home;
