import React, { useEffect } from 'react';

function PaymentForm({ amount, description, userId, onSuccess, onClose }) {
  useEffect(() => {
    const createPayment = async () => {
      try {
        const response = await fetch('/api/create_payment', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ amount, description, userId }),
        });

        const data = await response.json();

        // Загружаем виджет YooKassa
        const script = document.createElement('script');
        script.src = 'https://yookassa.ru/checkout-widget/v1/checkout-widget.js';
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
          const checkout = new window.YooMoneyCheckoutWidget({
            confirmation_token: data.confirmationToken,  // Используем токен подтверждения из сервера
            return_url: 'https://insectwar.ru/#/payment_success',  // URL возврата после успешной оплаты
            methods: ['bank_card', 'sbp'], // Оставляем только банковские карты и систему быстрых платежей (СПБ)
            error_callback: (error) => {
              console.error('Ошибка платежа:', error);
              alert('Произошла ошибка при оплате. Попробуйте позже.');
              onClose();
            },
            success_callback: (paymentId) => {
              console.log('Платеж успешно завершен:', paymentId);
              onSuccess(paymentId);
            },
          });

          checkout.render('payment-form-container'); // Элемент, куда будет встроен виджет
        };

        // Очистка при размонтировании компонента
        return () => {
          document.body.removeChild(script);
        };
      } catch (error) {
        console.error('Ошибка при создании платежа:', error);
        alert('Ошибка при создании платежа. Попробуйте позже.');
        onClose();
      }
    };

    createPayment();
  }, [amount, description, userId, onSuccess, onClose]);

  return (
    <div id="payment-form" style={{
      position: 'fixed', top: '0', left: '0', right: '0', bottom: '0',
      background: 'rgba(0,0,0,0.5)', zIndex: '1000',
      display: 'flex', alignItems: 'center', justifyContent: 'center'
    }}>
      <div style={{
        position: 'relative', background: '#fff', padding: '20px',
        borderRadius: '8px', width: '90%', maxWidth: '500px', maxHeight: '90%',
        overflowY: 'auto'
      }}>
        <div id="payment-form-container" style={{ minWidth: '300px', minHeight: '400px' }}>
          {/* Здесь будет отображаться платежная форма */}
        </div>
        <button onClick={onClose} style={{
          position: 'absolute', top: '10px', right: '10px',
          background: 'transparent', border: 'none', fontSize: '1.5rem', cursor: 'pointer'
        }}>×</button>
      </div>
    </div>
  );
}

export default PaymentForm;
