// src/App.js
import React, { useEffect } from 'react';
import { HashRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from './components/Home';
import Profile from './components/Profile';
import Chat from './components/Chat';
import Notifications from './components/Notifications';
import Dialogs from './components/Dialogs';
import Bee from './components/Bee';
import TopNav from './components/TopNav';
import StickyNav from './components/StickyNav';
import PveLevel from './components/PveLevel';
import PveBattle from "./components/PveBattle";
import PveMap from "./components/PveMap"; 
import Game from "./components/Game"; // Импортируем компонент Game

import './App.css';
import PaymentSuccess from './components/PaymentSuccess'; // Импортируем компонент PaymentSuccess

function App() {
  return (
    <Router>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
      </Helmet>
      <div>
        <BackButtonHandler />
        <ConditionalTopNav />
        <ConditionalPadding>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/dialogs/*" element={<Dialogs />} />
            <Route path="/bee" element={<Bee />} />
            <Route path="/pve/:level" element={<PveLevel />} />
            <Route path="/pve_battle" element={<PveBattle />} />
            <Route path="/pvemap" element={<PveMap />} />
            <Route path="/game" element={<Game />} />
            <Route path="/payment_success" element={<PaymentSuccess />} /> {/* Добавляем маршрут для PaymentSuccess */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </ConditionalPadding>
        <ConditionalStickyNav />
      </div>
    </Router>
  );
}


const ConditionalTopNav = () => {
  const location = useLocation();
  const showTopNav = location.pathname !== '/' 
    && location.pathname !== '/dialogs' 
    && !location.pathname.startsWith('/dialogs/private_chat') 
    && location.pathname !== '/bee' 
    && location.pathname !== '/profile'
    && !location.pathname.startsWith('/pve')
    && location.pathname !== '/pvemap'
    && location.pathname !== '/game'; // Добавляем условие для game страницы

  return showTopNav ? <TopNav /> : null;
};

const ConditionalPadding = ({ children }) => {
  const location = useLocation();

  const addPadding = location.pathname !== '/' 
    && location.pathname !== '/dialogs' 
    && !location.pathname.startsWith('/dialogs/private_chat')
    && location.pathname !== '/bee'
    && !location.pathname.startsWith('/pve')
    && location.pathname !== '/pvemap'
    && location.pathname !== '/game'; // Добавляем условие для game страницы

  const addBottomPadding = location.pathname === '/pvemap';

  return (
    <div style={{ 
      paddingTop: addPadding ? '50px' : '0', 
      paddingBottom: addPadding || addBottomPadding ? '50px' : '0' 
    }}>
      {children}
    </div>
  );
};

const ConditionalStickyNav = () => {
  const location = useLocation();
  const showStickyNav = location.pathname !== '/dialogs/private_chat' && location.pathname !== '/game'; // Добавляем условие для game страницы

  return showStickyNav ? <StickyNav /> : null;
};

const BackButtonHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.BackButton.show();

    const handleBackButton = () => {
      if (location.pathname !== '/') {
        navigate(-1);
      } else {
        tg.close();
      }
    };

    tg.BackButton.onClick(handleBackButton);

    return () => {
      tg.BackButton.offClick(handleBackButton);
    };
  }, [navigate, location]);

  return null;
};

export default App;
