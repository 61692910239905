import React, { useMemo } from "react";
import PropTypes from "prop-types"; // Для типизации пропсов
import { FaStar, FaHeart, FaFistRaised } from "react-icons/fa"; // Импортируем иконки
import "./CardProfile.css"; // Импортируем стили для профиля карты

const CardProfile = React.memo(({ card, onClose }) => {
  // Мемоизация расчета прогресса опыта
  const expProgress = useMemo(
    () => calculateExpProgress(card.current_exp, card.current_level),
    [card.current_exp, card.current_level]
  );

  // Мемоизация расчета ширины прогресс-баров (HP и Damage)
  const hpBarWidth = useMemo(() => calculateBarWidth(card.hp), [card.hp]);
  const damageBarWidth = useMemo(() => calculateBarWidth(card.damage), [card.damage]);

  return (
    <div
      className="card-profile-overlay"
      onClick={onClose}
      role="dialog"
      aria-modal="true"
      aria-labelledby="card-profile-title"
    >
      <div
        className="card-profile-container"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 id="card-profile-title" className="card-profile-title">
          {card.name}
        </h2>
        <div className="card-profile-image-container">
          <img
            src={card.image_url}
            alt={card.name}
            className="card-profile-image"
            loading="lazy"
          />
          <div className="stars-profile" aria-label={`${card.stars} звёзд`}>
            {Array.from({ length: card.stars }).map((_, index) => (
              <FaStar key={index} className="profile-star-icon" />
            ))}
          </div>
          <div
            className="profile-level-container"
            aria-label={`Уровень ${card.current_level}`}
          >
            <div className="profile-level-circle">
              {card.current_level}
            </div>
            <div
              className="profile-exp-bar"
              aria-label={`Опыт ${card.current_exp} из ${Math.floor(10 * Math.pow(2.5, card.current_level - 1))}`}
            >
              <div
                className="profile-exp-progress"
                style={{ width: `${expProgress}%` }}
              ></div>
              <div className="profile-exp-text">
                {card.current_exp} / {Math.floor(10 * Math.pow(2.5, card.current_level - 1))}
              </div>
            </div>
          </div>
        </div>
        <div className="card-profile-details">
          <div className="profile-stat-container">
            <FaHeart className="profile-stat-icon" aria-label="HP" />
            <div className="profile-stat-bar" aria-label={`HP: ${card.hp}`}>
              <div
                className="profile-stat-fill"
                style={{ width: `${hpBarWidth}%` }}
              ></div>
            </div>
            <div className="profile-stat-value">{card.hp}</div>
          </div>
          <div className="profile-stat-container">
            <FaFistRaised className="profile-stat-icon" aria-label="Damage" />
            <div className="profile-stat-bar" aria-label={`Damage: ${card.damage}`}>
              <div
                className="profile-stat-fill"
                style={{ width: `${damageBarWidth}%` }}
              ></div>
            </div>
            <div className="profile-stat-value">{card.damage}</div>
          </div>
        </div>
        <button
          className="profile-close-button"
          onClick={onClose}
          aria-label="Закрыть профиль карты"
        >
          Закрыть
        </button>
      </div>
    </div>
  );
});

// Функция для расчёта прогресса опыта
const calculateExpProgress = (currentExp, level) => {
  let requiredExp = 10;
  for (let i = 2; i <= level; i++) {
    requiredExp = Math.floor(requiredExp * 2.5);
  }
  return Math.min((currentExp / requiredExp) * 100, 100);
};

// Функция для расчёта ширины прогресс-бара
const calculateBarWidth = (value) => {
  const maxValue = 10000;
  return Math.min((value / maxValue) * 100, 100);
};

// Типизация пропсов
CardProfile.propTypes = {
  card: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image_url: PropTypes.string.isRequired,
    stars: PropTypes.number.isRequired,
    current_level: PropTypes.number.isRequired,
    current_exp: PropTypes.number.isRequired,
    hp: PropTypes.number.isRequired,
    damage: PropTypes.number.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CardProfile;
