import React, { useState, useEffect, useCallback } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Cropper from 'react-easy-crop';
import 'react-easy-crop/react-easy-crop.css';
import './Profile.css';
import StickyNav from './StickyNav';

function Profile() {
  const [profile, setProfile] = useState({
    username: 'Username',
    profileImage: 'https://via.placeholder.com/100',
    reputation: 0,
    level: 1,
    score: 0,
  });
  const [currentUser, setCurrentUser] = useState({
    id: null,
    username: '',
    score: 0,
  });
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showCropContainer, setShowCropContainer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [editUsername, setEditUsername] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showFriendsModal, setShowFriendsModal] = useState(false);
  const [showSendResourcesModal, setShowSendResourcesModal] = useState(false);
  const [friends, setFriends] = useState([]);
  const [friendStatus, setFriendStatus] = useState('none');
  const [resourceAmount, setResourceAmount] = useState('');
  const [recipientId, setRecipientId] = useState(null);

  const navigate = useNavigate();
  const tg = window.Telegram.WebApp;
  const [searchParams] = useSearchParams();
  const userId = searchParams.get('user_id');

  useEffect(() => {
    tg.ready();
    tg.expand();
    setCurrentUser({
      id: tg.initDataUnsafe.user.id,
      username: tg.initDataUnsafe.user.username,
      score: tg.initDataUnsafe.user.score,
    });
    const isCurrentUser = userId === tg.initDataUnsafe.user.id.toString();
    setIsCurrentUser(isCurrentUser);
    loadProfile(userId, isCurrentUser);
    if (!isCurrentUser) {
      checkFriendStatus(tg.initDataUnsafe.user.id, userId);
    }
  }, [userId]);

  const loadProfile = async (userId, isCurrentUser) => {
    console.log('Loading profile for user ID:', userId);
    try {
      const response = await fetch(`/load_user_profile?user_id=${userId}`);
      const data = await response.json();
      const timestamp = new Date().getTime();
      setProfile({
        username: data.username,
        profileImage: `${data.profileImage || 'https://via.placeholder.com/100'}?t=${timestamp}`,
        reputation: data.reputation,
        level: data.level,
        score: data.score,
      });
      if (isCurrentUser) {
        setCurrentUser((prev) => ({
          ...prev,
          username: data.username,
          score: data.score,
        }));
      }
    } catch (error) {
      console.error('Ошибка загрузки профиля пользователя:', error);
    }
  };

  const handleImageClick = () => {
    if (isCurrentUser) {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.style.display = 'none';
      input.onchange = (e) => {
        const file = e.target.files[0];
        if (file) {
          setSelectedFile(file);
          const reader = new FileReader();
          reader.onload = (e) => {
            setShowCropContainer(true);
            const image = new Image();
            image.src = e.target.result;
            image.onload = () => {
              setCroppedAreaPixels(null);
              setCrop({ x: 0, y: 0 });
              setZoom(1);
            };
          };
          reader.readAsDataURL(file);
        }
      };
      document.body.appendChild(input);
      input.click();
      document.body.removeChild(input);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleSaveProfileImage = async () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.src = URL.createObjectURL(selectedFile);

    image.onload = () => {
      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;

      ctx.drawImage(
        image,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height
      );

      canvas.toBlob(async (blob) => {
        const url = URL.createObjectURL(blob);
        setProfile((prev) => ({
          ...prev,
          profileImage: url,
        }));
        const croppedFile = new File([blob], 'profile.jpg', { type: 'image/jpeg' });
        setSelectedFile(croppedFile);
        setShowCropContainer(false);

        const user = tg.initDataUnsafe.user;
        setLoading(true);
        const formData = new FormData();
        formData.append('user_id', user.id);
        formData.append('profile_image', croppedFile);

        try {
          const response = await fetch('/update_profile', {
            method: 'POST',
            body: formData,
          });
          const data = await response.json();
          if (response.ok) {
            const timestamp = new Date().getTime();
            setProfile((prev) => ({
              ...prev,
              profileImage: `${data.profileImageUrl}?t=${timestamp}`,
            }));
            setSelectedFile(null);
          } else {
            console.error('Ошибка обновления фото профиля:', data.message);
          }
        } catch (error) {
          console.error('Ошибка обновления фото профиля:', error);
        } finally {
          setLoading(false);
        }
      }, 'image/jpeg', 0.9);
    };
  };

  const saveUsername = async () => {
    const user = currentUser;
    try {
      const response = await fetch('/update_username', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: user.id, username: profile.username }),
      });
      const data = await response.json();
      if (response.ok) {
        setCurrentUser((prev) => ({
          ...prev,
          username: profile.username,
        }));
        setShowSaveButton(false);
        setEditUsername(false);
      } else {
        console.error('Ошибка обновления имени пользователя:', data.message);
      }
    } catch (error) {
      console.error('Ошибка обновления имени пользователя:', error);
    }
  };

  const handleAddFriend = async () => {
    const senderId = currentUser.id;
    const receiverId = userId;

    try {
      const response = await fetch('/send_friend_request', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sender_id: senderId, receiver_id: receiverId }),
      });
      const result = await response.json();
      console.log(result.message);
      setFriendStatus('pending');
      updateFriendButton('pending');
    } catch (error) {
      console.error('Ошибка отправки запроса на добавление в друзья:', error);
    }
  };

  const checkFriendStatus = async (userId, profileId) => {
    try {
      const response = await fetch(`/friend_status?user_id=${userId}&profile_id=${profileId}`);
      const data = await response.json();
      setFriendStatus(data.status);
      updateFriendButton(data.status);
    } catch (error) {
      console.error('Ошибка проверки статуса запроса на добавление в друзья:', error);
    }
  };

  const updateFriendButton = (status) => {
    const addFriendButton = document.getElementById('addFriendButton');
    if (!addFriendButton) {
      console.error('Кнопка добавления в друзья не найдена.');
      return;
    }
    
    if (status === 'none') {
      addFriendButton.textContent = 'Добавить в друзья';
      addFriendButton.disabled = false;
    } else if (status === 'pending') {
      addFriendButton.textContent = 'Запрос отправлен';
      addFriendButton.disabled = true;
    } else if (status === 'accepted') {
      addFriendButton.textContent = 'Твой друг';
      addFriendButton.disabled = true;
    } else if (status === 'declined') {
      addFriendButton.textContent = 'Добавить в друзья';
      addFriendButton.disabled = false;
    }
  };

  const handleUsernameChange = (e) => {
    setProfile((prev) => ({
      ...prev,
      username: e.target.value,
    }));
    setShowSaveButton(true);
  };

  const handleUsernameClick = () => {
    if (isCurrentUser) {
      setEditUsername(true);
    }
  };

  const handleRetake = () => {
    setSelectedFile(null);
    setShowCropContainer(false);
    setShowSaveButton(false);
    handleImageClick();
  };

  const handleFriendsButtonClick = async () => {
    const user = currentUser;
    try {
      const response = await fetch(`/load_friends?user_id=${user.id}`);
      const data = await response.json();
      setFriends(data);
      setShowFriendsModal(true);
    } catch (error) {
      console.error('Ошибка загрузки списка друзей:', error);
    }
  };

  const handleCloseFriendsModal = () => {
    setShowFriendsModal(false);
  };

  const handleFriendClick = (friendId) => {
    setShowFriendsModal(false);
    navigate(`/profile?user_id=${friendId}`);
  };

  const handleSendResourcesClick = (friendId) => {
    setRecipientId(friendId);
    setShowSendResourcesModal(true);
  };

  const handleSendResources = async () => {
    const amount = parseInt(resourceAmount, 10);
    if (isNaN(amount) || amount <= 0 || amount > currentUser.score) {
      alert('Введите корректное количество ресурсов для отправки.');
      return;
    }

    try {
      const response = await fetch('/send_resources', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sender_id: currentUser.id, recipient_id: recipientId, amount }),
      });
      const data = await response.json();
      if (response.ok) {
        setCurrentUser((prev) => ({
          ...prev,
          score: prev.score - amount,
        }));
        await loadProfile(recipientId, false); // Обновить профиль получателя
        setShowSendResourcesModal(false);
        setResourceAmount('');
      } else {
        alert('Ошибка отправки ресурсов: ' + data.message);
      }
    } catch (error) {
      console.error('Ошибка отправки ресурсов:', error);
    }
  };

  return (
    <div id="content">
      <div id="profileViewModal">
        <div id="profileImageContainer" style={{ position: 'relative' }}>
          <img id="viewProfileImage" src={profile.profileImage} alt="Profile Picture" onClick={handleImageClick} />
          {loading && <div id="profileLoadingAnimation"><i className="fas fa-spinner fa-spin"></i></div>}
        </div>
        <div className="content">
          <div className="info">
            <div className="left-side">
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2L13.09 8.26L18 9.27L14.5 13.97L15.18 19.73L12 16.56L8.82 19.73L9.5 13.97L6 9.27L10.91 8.26L12 2Z" fill="#FFD700"/>
              </svg>
              <span id="viewProfileLevel">{profile.level}</span>
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2Z" fill="#FFD700"/>
              </svg>
              <span id="viewProfileReputation">{profile.reputation}</span>
            </div>
            <div className="right-side">
              <span id="viewProfileScore">Ресурсы: {profile.score}</span>
            </div>
          </div>
          <div className="username" id="viewProfileUsername">
            {editUsername ? (
              <input
                type="text"
                value={profile.username}
                onChange={handleUsernameChange}
                onBlur={() => setEditUsername(false)}
                autoFocus
              />
            ) : (
              <span onClick={handleUsernameClick}>{profile.username}</span>
            )}
          </div>
          {isCurrentUser && showSaveButton && <button id="saveUsernameButton" onClick={saveUsername}>Сохранить имя</button>}
          {isCurrentUser && <button id="friendsButton" onClick={handleFriendsButtonClick}>Друзья</button>}
          {!isCurrentUser && friendStatus === 'accepted' && (
            <button id="sendResourcesButton" onClick={() => handleSendResourcesClick(userId)}>Отправить ресурсы</button>
          )}
          {!isCurrentUser && friendStatus === 'none' && (
            <button id="addFriendButton" onClick={handleAddFriend}>Добавить в друзья</button>
          )}
          {!isCurrentUser && friendStatus === 'pending' && (
            <button id="addFriendButton" disabled>Запрос отправлен</button>
          )}
          {!isCurrentUser && friendStatus === 'accepted' && (
            <button id="addFriendButton" disabled>Твой друг</button>
          )}
          {!isCurrentUser && friendStatus === 'declined' && (
            <button id="addFriendButton" onClick={handleAddFriend}>Добавить в друзья</button>
          )}
        </div>
      </div>

      {showCropContainer && (
        <div id="cropContainer">
          <div className="cropper-wrapper">
            <Cropper
              image={URL.createObjectURL(selectedFile)}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>
          <div className="crop-buttons">
            <button id="saveCropButton" onClick={handleSaveProfileImage}>Сохранить</button>
            <button id="retakeButton" onClick={handleRetake}>Изменить</button>
          </div>
        </div>
      )}

      {showFriendsModal && (
        <div id="friendsListModal">
          <div className="content">
            <span className="close" onClick={handleCloseFriendsModal}>&times;</span>
            <div id="friendsList">
              {friends.map((friend) => (
                <div key={friend.user_id} className="friend-item" onClick={() => handleFriendClick(friend.user_id)}>
                  <img src={friend.profile_image || 'https://via.placeholder.com/40'} alt={friend.username} />
                  <div className="friend-username">{friend.username}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {showSendResourcesModal && (
        <div id="sendResourcesModal">
          <div className="content">
            <span className="close" onClick={() => setShowSendResourcesModal(false)}>&times;</span>
            <h3>Отправить ресурсы</h3>
            <input
              type="number"
              value={resourceAmount}
              onChange={(e) => setResourceAmount(e.target.value)}
              placeholder="Количество ресурсов"
            />
            <button onClick={handleSendResources}>Отправить</button>
          </div>
        </div>
      )}

      <StickyNav />
    </div>
  );
}

export default Profile;
