import React, { useEffect, useState, useRef } from 'react';
import PlayerCards from './PlayerCards';
import './NewCardModal.css'; // Обновленные стили модального окна

const NewCardModal = ({ card, onClose }) => {
  const [shuffledCards, setShuffledCards] = useState([]);
  const [isSpinning, setIsSpinning] = useState(true);
  const scrollRef = useRef(null);
  const cardItemRef = useRef(null);

  // Генерация случайного массива карт с дублями
  const generateRandomCards = (cards, count = 50) => {
    const randomCards = Array.from({ length: count - 1 }, () => {
      const randomIndex = Math.floor(Math.random() * cards.length);
      return cards[randomIndex];
    });
    return randomCards;
  };

  // Вставка выигранной карты в случайное место массива
  const insertWinningCard = (cards, winningCard) => {
    const randomIndex = Math.floor(Math.random() * cards.length);
    cards.splice(randomIndex, 0, winningCard);
    return cards;
  };

  // Загрузка карт с сервера и генерация массива
  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await fetch('/api/cards');
        const cards = await response.json();

        let randomCards = generateRandomCards(cards, 50);
        randomCards = insertWinningCard(randomCards, card);

        setShuffledCards(randomCards);
      } catch (error) {
        console.error('Ошибка при загрузке карт:', error);
      }
    };

    fetchCards();
  }, [card]);

  // Функция для плавной прокрутки с замедлением
  const smoothScrollTo = (element, target, duration) => {
    const start = element.scrollLeft;
    const change = target - start;
    const increment = 20;
    let currentTime = 0;

    const animateScroll = () => {
      currentTime += increment;
      const val = easeOutCubic(currentTime, start, change, duration);
      element.scrollLeft = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      } else {
        setIsSpinning(false);
      }
    };

    animateScroll();
  };

  // Функция замедления (ease out cubic)
  const easeOutCubic = (t, b, c, d) => {
    t /= d;
    t--;
    return c * (t * t * t + 1) + b;
  };

  // Анимация прокрутки карт с замедлением
  useEffect(() => {
    if (isSpinning && shuffledCards.length > 0 && scrollRef.current && cardItemRef.current) {
      const container = scrollRef.current;
      const containerWidth = container.clientWidth; // Ширина контейнера
      const cardElement = cardItemRef.current;

      const cardWidth = cardElement.offsetWidth;
      const cardStyle = getComputedStyle(cardElement);
      const cardMarginLeft = parseInt(cardStyle.marginLeft, 10);
      const cardMarginRight = parseInt(cardStyle.marginRight, 10);
      const totalCardWidth = cardWidth + cardMarginLeft + cardMarginRight;

      const stopAtIndex = shuffledCards.findIndex((c) => c.card_id === card.card_id);

      // Вычисляем позицию, чтобы середина выигрышной карты была в центре контейнера
      const targetScrollPosition = stopAtIndex * totalCardWidth + (cardWidth / 2) - (containerWidth / 2);

      // Ограничиваем целевую позицию в пределах возможных значений
      const maxScrollLeft = container.scrollWidth - container.clientWidth;
      const finalScrollPosition = Math.min(Math.max(targetScrollPosition, 0), maxScrollLeft);

      // Параметры анимации
      const duration = 2000; // Продолжительность анимации в миллисекундах

      smoothScrollTo(container, finalScrollPosition, duration);
    }
  }, [isSpinning, card, shuffledCards]);

  return (
    <div className="new-card-modal">
      <div className="new-card-modal-content">
        <button className="close-btn" onClick={onClose} aria-label="Закрыть">&times;</button>
        <h3>Поздравляем! Вы получили новую карту:</h3>
        <div className="card-scroll-container" ref={scrollRef}>
          <div className="card-list">
            {shuffledCards.map((c, index) => (
              <div 
                key={index} 
                className={`card-item ${!isSpinning && c.card_id === card.card_id ? 'highlight' : ''}`}
                ref={index === 0 ? cardItemRef : null}
              >
                <PlayerCards card={c} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCardModal;
