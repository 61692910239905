import React, { useState, useEffect } from "react";
import BattleTeam from "./BattleTeam";
import EnemyBattleTeam from "./EnemyBattleTeam";
import "./PveBattle.css";

const PveBattle = ({ battleTeam, setBattleTeam, pveTeam, setPveTeam, onBattleEnd }) => {
  const [selectedPlayerCardIndex, setSelectedPlayerCardIndex] = useState(null);
  const [isPlayerTurn, setIsPlayerTurn] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);
  const [damageDealt, setDamageDealt] = useState({ value: 0, x: 0, y: 0 });
  const [attackingCardIndex, setAttackingCardIndex] = useState(null);
  const [attackedCardIndex, setAttackedCardIndex] = useState(null);
  const [movePosition, setMovePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (!isPlayerTurn && !isAnimating) {
      aiTurn();
    }
  }, [isPlayerTurn, isAnimating]);

  useEffect(() => {
    // При инициализации карты добавляем maxHp
    const initializeMaxHp = (team) => {
      return team.map((card) => {
        if (card) {
          return { ...card, maxHp: card.hp };
        }
        return card;
      });
    };

    setBattleTeam(initializeMaxHp(battleTeam));
    setPveTeam(initializeMaxHp(pveTeam));
  }, []);

  const calculateMovePosition = (attackerIndex, targetIndex, isPlayer) => {
    const attacker = document.querySelector(
      isPlayer
        ? `.pve-player-team .slot:nth-child(${attackerIndex + 1})`
        : `.pve-opponent-team .enemy-slot:nth-child(${attackerIndex + 1})`
    );
    const target = document.querySelector(
      isPlayer
        ? `.pve-opponent-team .enemy-slot:nth-child(${targetIndex + 1})`
        : `.pve-player-team .slot:nth-child(${targetIndex + 1})`
    );

    if (attacker && target) {
      const attackerRect = attacker.getBoundingClientRect();
      const targetRect = target.getBoundingClientRect();

      const x = targetRect.left - attackerRect.left;
      const y = targetRect.top - attackerRect.top;

      return { x, y };
    }

    return { x: 0, y: 0 };
  };

  const aiTurn = () => {
    setIsAnimating(true);
    const aliveEnemyCards = pveTeam.filter((card) => card !== null);
    if (aliveEnemyCards.length === 0) return;

    const randomEnemyCard = aliveEnemyCards[Math.floor(Math.random() * aliveEnemyCards.length)];

    const alivePlayerCards = battleTeam
      .map((card, index) => ({ card, index }))
      .filter((item) => item.card !== null);

    if (randomEnemyCard && alivePlayerCards.length > 0) {
      const randomPlayerTarget = alivePlayerCards[Math.floor(Math.random() * alivePlayerCards.length)];
      const updatedBattleTeam = [...battleTeam];
      const playerCard = randomPlayerTarget.card;

      playerCard.hp -= randomEnemyCard.damage;

      const targetElement = document.querySelector(`.pve-player-team .slot:nth-child(${randomPlayerTarget.index + 1})`);
      const targetRect = targetElement.getBoundingClientRect();

      setDamageDealt({
        value: randomEnemyCard.damage,
        x: targetRect.left + targetRect.width / 2,
        y: targetRect.top,
      });

      setAttackingCardIndex(pveTeam.indexOf(randomEnemyCard));
      setAttackedCardIndex(randomPlayerTarget.index);

      const position = calculateMovePosition(
        pveTeam.indexOf(randomEnemyCard),
        randomPlayerTarget.index,
        false
      );
      setMovePosition(position);

      setTimeout(() => {
        setDamageDealt({ value: 0, x: 0, y: 0 });

        if (playerCard.hp <= 0) {
          updatedBattleTeam[randomPlayerTarget.index] = null;
          if (randomPlayerTarget.index === selectedPlayerCardIndex) {
            setSelectedPlayerCardIndex(null); // Сбрасываем выбор, если выбранная карта умерла
          }
        }
        setBattleTeam(updatedBattleTeam);

        const playerLost = updatedBattleTeam.every((card) => card === null);

        if (playerLost) {
          onBattleEnd(false);
        } else {
          setIsPlayerTurn(true);
        }

        setIsAnimating(false);
        setAttackingCardIndex(null);
        setAttackedCardIndex(null);
        setMovePosition({ x: 0, y: 0 });
      }, 1000);
    }
  };

  const handlePlayerSlotClick = (index) => {
    if (isAnimating && isPlayerTurn) return; // Запрещаем смену карты, если идет атака игрока
    setSelectedPlayerCardIndex(index);
  };

  const handleEnemySlotClick = (enemyIndex, event) => {
    if (!isPlayerTurn || selectedPlayerCardIndex === null || isAnimating) return;

    const playerCard = battleTeam[selectedPlayerCardIndex];
    const enemyCard = pveTeam[enemyIndex];

    if (playerCard && enemyCard) {
      enemyCard.hp -= playerCard.damage;

      const targetElement = document.querySelector(`.pve-opponent-team .enemy-slot:nth-child(${enemyIndex + 1})`);
      const targetRect = targetElement.getBoundingClientRect();

      setDamageDealt({
        value: playerCard.damage,
        x: targetRect.left + targetRect.width / 2,
        y: targetRect.top,
      });

      setAttackingCardIndex(selectedPlayerCardIndex);
      setAttackedCardIndex(enemyIndex);

      const position = calculateMovePosition(
        selectedPlayerCardIndex,
        enemyIndex,
        true
      );
      setMovePosition(position);

      setIsAnimating(true);

      setTimeout(() => {
        setDamageDealt({ value: 0, x: 0, y: 0 });

        const updatedPveTeam = [...pveTeam];

        if (updatedPveTeam[enemyIndex].hp <= 0) {
          updatedPveTeam[enemyIndex] = null;
        }

        setPveTeam(updatedPveTeam);

        const playerWon = updatedPveTeam.every((card) => card === null);

        if (playerWon) {
          onBattleEnd(true);
        } else {
          setIsPlayerTurn(false);
        }

        setIsAnimating(false);
        setAttackingCardIndex(null);
        setAttackedCardIndex(null);
        setMovePosition({ x: 0, y: 0 });
      }, 1000);
    }
  };

  return (
    <div className="pve-battle-container">
      <h1>Битва</h1>
      {damageDealt.value > 0 && (
        <div
          className="damage-indicator"
          style={{ left: damageDealt.x, top: damageDealt.y }}
        >
          -{damageDealt.value}
        </div>
      )}
      <div className="pve-opponent-team">
        <EnemyBattleTeam
          battleTeam={pveTeam}
          onSlotClick={handleEnemySlotClick}
          attackingCardIndex={attackingCardIndex}
          attackedCardIndex={attackedCardIndex}
          isPlayerTurn={isPlayerTurn}
          movePosition={movePosition}
        />
      </div>
      <div className="pve-player-team">
        <BattleTeam
          battleTeam={battleTeam}
          setBattleTeam={setBattleTeam}
          onSlotClick={handlePlayerSlotClick}
          selectedPlayerCardIndex={selectedPlayerCardIndex}
          attackingCardIndex={attackingCardIndex}
          attackedCardIndex={attackedCardIndex}
          isPlayerTurn={isPlayerTurn}
          movePosition={movePosition}
          pveMode={true}
        />
      </div>
    </div>
  );
};

export default PveBattle;
