import React, { useState, useEffect } from 'react';
import './ProfileShip.css';

const TOTAL_SLOTS = 6; // Общее количество слотов для деталей

const ProfileShip = ({ onClose, userId }) => {
  const [shipLevel, setShipLevel] = useState(null); // Состояние для уровня корабля
  const [slots, setSlots] = useState(Array(TOTAL_SLOTS).fill(null)); // Массив слотов для деталей

  useEffect(() => {
    // Получаем детали пользователя и уровень корабля с сервера
    fetch(`/api/user/${userId}/ship_info`)
      .then((response) => response.json())
      .then((data) => {
        const newSlots = [...slots];
        data.parts.forEach((part) => {
          if (part.part_type <= TOTAL_SLOTS) {
            newSlots[part.part_type - 1] = {
              ...part,
              image_url: `/images/parts/part_${part.part_type}.png`, // Указываем путь к изображению детали
            };
          }
        });
        setSlots(newSlots);
        setShipLevel(data.shipLevel); // Устанавливаем уровень корабля из данных сервера
      })
      .catch((error) => console.error('Ошибка загрузки деталей и уровня корабля:', error));
  }, [userId]);

  // Не рендерим компонент, пока не загружен уровень корабля
  if (shipLevel === null) {
    return <div>Загрузка...</div>;
  }

  const handleUpgrade = () => {
    if (slots.every((slot) => slot !== null && slot.part_level === shipLevel)) {
      fetch(`/api/user/${userId}/upgrade_ship`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ level: shipLevel + 1 }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('Корабль улучшен:', data);
          setShipLevel(shipLevel + 1); // Обновляем уровень корабля
          setSlots(Array(TOTAL_SLOTS).fill(null)); // Очистка слотов после улучшения
        })
        .catch((error) => console.error('Ошибка улучшения корабля:', error));
    } else {
      alert('Не все слоты заполнены или уровни деталей не соответствуют уровню корабля!');
    }
  };

  return (
    <div className="game-profile-ship-modal">
      <button className="game-close-button" onClick={onClose}>
        X
      </button>
      <h2>Улучшение корабля</h2>
      <div className="game-ship-upgrade-container">
        <div className="game-slots-container game-left">
          {slots.slice(0, 3).map((slot, index) => (
            <div key={index} className="game-slot">
              {slot ? (
                <img src={slot.image_url} alt={`Деталь ${slot.part_type}`} />
              ) : (
                <span>Пусто</span>
              )}
            </div>
          ))}
        </div>
        <div className="game-ship-image-container">
          <img
            src={`/images/ships/ship_level_${shipLevel}.png`} // Путь к изображению корабля
            alt={`Корабль уровня ${shipLevel}`}
            className="game-ship-image"
          />
        </div>
        <div className="game-slots-container game-right">
          {slots.slice(3).map((slot, index) => (
            <div key={index + 3} className="game-slot">
              {slot ? (
                <img src={slot.image_url} alt={`Деталь ${slot.part_type}`} />
              ) : (
                <span>Пусто</span>
              )}
            </div>
          ))}
        </div>
      </div>
      <button className="game-upgrade-button" onClick={handleUpgrade}>
        Улучшить до уровня {shipLevel + 1}
      </button>
    </div>
  );
};

export default ProfileShip;
