import React, { useEffect, useState } from 'react';
import './LeaderboardModal.css'; // Подключаем стили

function LeaderboardModal({ show, onClose, currentUserId }) {
  const [leaderboard, setLeaderboard] = useState([]);

  useEffect(() => {
    const loadLeaderboard = async () => {
      try {
        const response = await fetch('/leaderboard');
        const data = await response.json();
        setLeaderboard(data);
      } catch (error) {
        console.error('Ошибка загрузки таблицы лидеров:', error);
      }
    };

    loadLeaderboard();
    const intervalId = setInterval(loadLeaderboard, 5000); // Обновляем таблицу каждые 5 секунд

    return () => clearInterval(intervalId); // Очищаем интервал при размонтировании компонента
  }, []);

  return (
    <div className={`modal ${show ? 'modal-show' : ''}`}>
      <div className="modal-content">
        <span className="close-btn" onClick={onClose}>
          &times;
        </span>
        <h2>Таблица Лидеров</h2>
        <ul id="leaderboard">
          {leaderboard.map((userItem, index) => (
            <li
              key={index}
              className={userItem.user_id === currentUserId ? 'highlight-row' : ''}
            >
              {index + 1}. <span className={userItem.user_id === currentUserId ? 'highlight-text' : ''}>{userItem.username}</span>: {userItem.score}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default LeaderboardModal;
