import React from "react";
import { FaStar } from "react-icons/fa";
import "./EnemyBattleTeam.css";

const EnemyBattleTeam = ({
  battleTeam,
  onSlotClick,
  attackingCardIndex,
  attackedCardIndex,
  isPlayerTurn,
  movePosition,
}) => {
  // Функция для определения цвета тени в зависимости от количества звёзд
  const getBoxShadowColor = (stars) => {
    switch (stars) {
      case 1:
        return "0 0 10px lightgray"; // 1 звезда - светло-серый цвет
      case 2:
        return "0 0 13px green"; // 2 звезды - зелёный цвет
      case 3:
        return "0 0 15px blue"; // 3 звезды - синий цвет
      case 4:
        return "0 0 17px purple"; // 4 звезды - фиолетовый цвет
      case 5:
        return "0 0 20px red, orange, yellow"; // 5 звёзд - огненный градиент
      default:
        return "0 0 10px transparent"; // Для карт без звёзд
    }
  };

  return (
    <div className="enemy-battle-team-container">
      <div className="enemy-slots-container">
        {battleTeam.map((card, index) => {
          const hpPercentage = card ? (card.hp / card.maxHp) * 100 : 0;

          return (
            <div
              key={index}
              className={`enemy-slot ${card ? "filled" : ""} ${
                index === attackingCardIndex && !isPlayerTurn ? "attacking-reverse" : ""
              } ${index === attackedCardIndex && isPlayerTurn ? "shaking" : ""}`}
              onClick={() => onSlotClick(index)}
              style={{
                "--hp-percentage": `${hpPercentage}%`,
                ...(index === attackingCardIndex ? {
                  "--move-x": `${movePosition.x}px`,
                  "--move-y": `${movePosition.y}px`,
                } : {}),
              }}
            >
              {card && (
                <>
                  <div className="enemy-small-stars">
                    {Array.from({ length: card.stars }).map((_, starIndex) => (
                      <FaStar key={starIndex} className="enemy-small-star-icon" />
                    ))}
                  </div>
                  <img
                    src={card.image_url}
                    alt={`Card ${index}`}
                    style={{ boxShadow: getBoxShadowColor(card.stars) }}
                  />
                  <div className="card-hp">HP: {card.hp}</div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EnemyBattleTeam;
