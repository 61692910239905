import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { ru } from 'date-fns/locale';
import './Dialogs.css';
import TopNav from './TopNav'; // Импортируем TopNav

function Dialogs() {
  const [dialogs, setDialogs] = useState([]);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [selectedFriend, setSelectedFriend] = useState(null);
  const tg = window.Telegram.WebApp;
  const socketRef = useRef(null);
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    tg.ready();
    tg.expand();
    loadDialogs();
  }, []);

  const loadDialogs = async () => {
    const user_id = tg.initDataUnsafe.user.id;
    try {
      const response = await fetch(`/load_dialogs?user_id=${user_id}`);
      const data = await response.json();
      setDialogs(data);
    } catch (error) {
      console.error('Ошибка загрузки диалогов:', error);
    }
  };

  const openPrivateChat = (friend_id) => {
    const friend = dialogs.find(dialog => dialog.friend_id === friend_id);
    setSelectedFriend(friend);

    if (socketRef.current) {
      socketRef.current.close();
    }
    const socket = new WebSocket('wss://insectwar.ru');
    socketRef.current = socket;

    socket.onopen = () => {
      console.log('WebSocket connection opened');
      const user_id = tg.initDataUnsafe.user.id;
      socket.send(JSON.stringify({ type: 'join_chat', user_id, friend_id }));
      loadPrivateMessages(friend_id);
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('Received WebSocket message:', data);
      if (data.type === 'new_private_message') {
        const messageData = data.data;
        if (messageData.sender_id === friend_id || messageData.receiver_id === friend_id) {
          addPrivateMessage(messageData);
        }
      }
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    socket.onclose = (event) => {
      console.log('WebSocket connection closed', event);
      if (!event.wasClean) {
        console.error('WebSocket connection closed unexpectedly');
      }
    };
  };

  const closePrivateChat = () => {
    setSelectedFriend(null);
    if (socketRef.current) {
      socketRef.current.close();
    }
  };

  const loadPrivateMessages = async (friend_id) => {
    const user_id = tg.initDataUnsafe.user.id;
    try {
      const response = await fetch(`/load_private_messages?user_id=${user_id}&friend_id=${friend_id}`);
      const data = await response.json();
      setMessages(data);
      scrollToBottom();
    } catch (error) {
      console.error('Ошибка загрузки сообщений:', error);
    }
  };

  const addPrivateMessage = (message) => {
    setMessages(prevMessages => [...prevMessages, message]);
    scrollToBottom();
  };

  const sendMessage = () => {
    if (message.trim()) {
      const user_id = tg.initDataUnsafe.user.id;
      const data = {
        type: 'send_private_message',
        user_id,
        receiver_id: selectedFriend.friend_id,
        message
      };
      if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
        socketRef.current.send(JSON.stringify(data));
        setMessage('');
      } else {
        console.error('WebSocket connection is not open');
      }
    }
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <>
      {!selectedFriend && <TopNav />} {/* Отображаем TopNav только если нет выбранного друга */}
      <div id="dialogs-container">
        {!selectedFriend ? (
          <>
            <h2>Личные Диалоги</h2>
            <ul id="dialogs-list">
              {dialogs.map(dialog => (
                <li key={dialog.friend_id} onClick={() => openPrivateChat(dialog.friend_id)}>
                  <div className="friend-info">
                    <img src={dialog.friend_profile_image || '/default-profile.png'} alt="profile" className="friend-profile-image" />
                    <div className="friend-details">
                      <span className="friend-name">{dialog.friend_username}</span>
                      <span className="last-message">{dialog.last_message}</span>
                      <span className="last-message-time">
                        {formatDistanceToNow(new Date(dialog.last_message_time), { addSuffix: true, locale: ru })}
                      </span>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <PrivateChat
            messages={messages}
            message={message}
            setMessage={setMessage}
            sendMessage={sendMessage}
            closePrivateChat={closePrivateChat}
            messagesEndRef={messagesEndRef}
            selectedFriend={selectedFriend}
          />
        )}
      </div>
    </>
  );
}

const PrivateChat = ({ messages, message, setMessage, sendMessage, closePrivateChat, messagesEndRef, selectedFriend }) => {
  return (
    <div id="privateChat">
      <div id="chatHeader">
        <img src={selectedFriend?.friend_profile_image || '/default-profile.png'} alt="profile" className="chat-header-profile-image" />
        <span className="chat-header-name">{selectedFriend?.friend_username}</span>
        <button onClick={closePrivateChat} className="chat-close-button">Закрыть диалог</button>
      </div>
      <div id="privateMessages">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.sender_id === window.Telegram.WebApp.initDataUnsafe.user.id ? 'mine' : 'other'}`}>
            <div className="message-text">{msg.message}</div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div id="privateChatInput">
        <input
          type="text"
          value={message}
          onChange={e => setMessage(e.target.value)}
          placeholder="Введите сообщение..."
          id="privateMessageInput"
        />
        <button onClick={sendMessage} id="sendPrivateMessageButton">Отправить</button>
      </div>
    </div>
  );
};

export default Dialogs;
