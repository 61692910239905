import React, { useState, useEffect } from "react";
import PlayerCards from "./PlayerCards"; // Импортируем компонент для отображения карточек
import BattleTeam from "./BattleTeam"; // Импортируем компонент для боевого отряда
import CardProfile from "./CardProfile"; // Импортируем новый компонент для профиля карты
import "./Bee.css"; // Импортируем стили для компонента Bee
import usePreventCollapse from './usePreventCollapse'; // Импортируем наш хук

const Bee = () => {
  usePreventCollapse(); // Вызов хука для предотвращения сворачивания окна

  const [cards, setCards] = useState([]);
  const [battleTeam, setBattleTeam] = useState(Array(5).fill(null)); // Инициализируем боевой отряд с 5 слотами, где хранятся объекты карт
  const [selectedSlot, setSelectedSlot] = useState(null); // Инициализируем состояние выбранного слота
  const [selectedCard, setSelectedCard] = useState(null); // Для открытия профиля карты

  useEffect(() => {
    const loadPlayerCards = async () => {
      const userId = window.Telegram.WebApp.initDataUnsafe.user.id;
      try {
        const response = await fetch(`/api/user/${userId}/cards`);
        const data = await response.json();
        setCards(data); // Устанавливаем состояние для карточек
      } catch (error) {
        console.error("Ошибка загрузки карт игрока:", error);
      }
    };

    loadPlayerCards();
  }, []);

  useEffect(() => {
    if (cards.length > 0) {
      const loadBattleTeam = async () => {
        const userId = window.Telegram.WebApp.initDataUnsafe.user.id;
        try {
          const response = await fetch(`/api/user/${userId}/battle_team`);
          const data = await response.json();

          const team = Array(5).fill(null);
          data.forEach((slot) => {
            const card = cards.find((c) => c.player_card_id === slot.player_card_id);
            if (card) {
              team[slot.slot] = card;
            }
          });
          setBattleTeam(team);
        } catch (error) {
          console.error("Ошибка загрузки боевого отряда:", error);
        }
      };

      loadBattleTeam();
    }
  }, [cards]);

  const updateBattleTeam = async (newTeam) => {
    const userId = window.Telegram.WebApp.initDataUnsafe.user.id;
    try {
      const payload = newTeam.map((card, index) => ({
        slot: index,
        player_card_id: card ? card.player_card_id : null,
      }));
      await fetch(`/api/user/${userId}/battle_team`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ battleTeam: payload }),
      });
    } catch (error) {
      console.error("Ошибка обновления боевого отряда:", error);
    }
  };

  const handleSlotClick = (index) => {
    const newTeam = [...battleTeam];
    if (newTeam[index]) {
      // Если в слоте уже есть карта, очищаем его
      newTeam[index] = null;
      setBattleTeam(newTeam);
      updateBattleTeam(newTeam);
      setSelectedSlot(null); // Сбрасываем выбранный слот
    } else {
      // Если слот пустой, выбираем его для размещения карты
      setSelectedSlot(index);
    }
  };

  const handleCardClick = (cardId) => {
    const selectedCard = cards.find(card => card.player_card_id === cardId);
    if (selectedSlot !== null && selectedCard) {
      // Проверяем на дублирование карты в слотах
      if (battleTeam.some((slot) => slot && slot.player_card_id === cardId)) {
        alert("Эта карточка уже добавлена в один из слотов!");
      } else {
        const newTeam = [...battleTeam];
        newTeam[selectedSlot] = selectedCard;
        setBattleTeam(newTeam);
        updateBattleTeam(newTeam);
        setSelectedSlot(null); // Сброс выбранного слота после добавления
      }
    } else {
      // Открываем профиль карты
      setSelectedCard(selectedCard);
    }
  };

  return (
    <div id="content" className="inventory-container">
      <div className="player-cards-grid">
        {cards.map(card => (
          <PlayerCards
            key={card.player_card_id}
            card={card}
            onCardClick={handleCardClick}
          />
        ))}
      </div>
      <BattleTeam
        battleTeam={battleTeam}
        setBattleTeam={(newTeam) => {
          setBattleTeam(newTeam);
          updateBattleTeam(newTeam);
        }}
        onSlotClick={handleSlotClick} // Передаем обновленную функцию выбора слота
      />
      {selectedCard && (
        <CardProfile
          card={selectedCard}
          onClose={() => setSelectedCard(null)}
        />
      )}
    </div>
  );
};

export default Bee;
