import React, { useMemo } from "react";
import "./PlayerCards.css"; // Импортируем стили для карточек
import { FaStar } from "react-icons/fa"; // Импортируем иконки звёзд из FontAwesome
import PropTypes from "prop-types"; // Для типизации пропсов

const PlayerCards = React.memo(({ card, onCardClick }) => {
  // Мемоизация расчета прогресса опыта
  const expProgress = useMemo(
    () => calculateExpProgress(card.current_exp, card.current_level),
    [card.current_exp, card.current_level]
  );

  // Мемоизация вычисления класса цвета границы
  const borderColorClass = useMemo(() => getBorderColorClass(card.stars), [card.stars]);

  return (
    <div
      className="card"
      onClick={() => onCardClick(card.player_card_id)}
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          onCardClick(card.player_card_id);
        }
      }}
      aria-label={`Карточка ${card.name}, уровень ${card.current_level}`}
    >
      <h3 className="card-name">{card.name}</h3>
      <div className="card-image">
        <div className="stars" aria-label={`${card.stars} звёзд`}>
          {Array.from({ length: card.stars }).map((_, index) => (
            <FaStar key={index} className="star-icon" />
          ))}
        </div>
        <img
          src={card.image_url}
          alt={card.name}
          loading="lazy"
          className={`card-img ${borderColorClass}`}
        />
        <div className="card-level" aria-label={`Уровень ${card.current_level}`}>
          {card.current_level}
        </div>
        <div className="exp-bar" aria-label={`Опыт ${card.current_exp} из ${Math.floor(10 * Math.pow(2.5, card.current_level - 1))}`}>
          <div className="exp-progress" style={{ width: `${expProgress}%` }}></div>
          <div className="exp-text">
            {card.current_exp} / {Math.floor(10 * Math.pow(2.5, card.current_level - 1))}
          </div>
        </div>
      </div>
    </div>
  );
});

// Функция для расчёта прогресса опыта
const calculateExpProgress = (currentExp, level) => {
  let requiredExp = 10;

  for (let i = 2; i <= level; i++) {
    requiredExp = Math.floor(requiredExp * 2.5);
  }

  // Процент заполнения шкалы прогресса
  return Math.min((currentExp / requiredExp) * 100, 100);
};

// Функция для получения CSS-класса цвета границы
const getBorderColorClass = (stars) => {
  switch (stars) {
    case 1:
      return "border-gray";
    case 2:
      return "border-green";
    case 3:
      return "border-blue";
    case 4:
      return "border-purple";
    case 5:
      return "border-gradient";
    default:
      return "border-white";
  }
};

// Типизация пропсов
PlayerCards.propTypes = {
  card: PropTypes.shape({
    player_card_id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    stars: PropTypes.number.isRequired,
    image_url: PropTypes.string.isRequired,
    current_level: PropTypes.number.isRequired,
    current_exp: PropTypes.number.isRequired,
  }).isRequired,
  onCardClick: PropTypes.func.isRequired,
};

export default PlayerCards;
