// /var/www/www-root/data/www/insectwar.ru/game/src/components/PaymentSuccess.js
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function PaymentSuccess() {
  const location = useLocation();

  useEffect(() => {
    // Обработка успешного или неуспешного платежа
    const params = new URLSearchParams(location.search);
    const paymentStatus = params.get('payment_status');

    if (paymentStatus === 'success') {
      alert('Платёж успешно завершён!');
      // Дополнительные действия, например, обновление баланса пользователя
      // Можно выполнить запрос к серверу для обновления состояния
    } else {
      alert('Платёж не был завершён.');
      // Дополнительные действия при неудаче
    }
  }, [location]);

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h1>Страница оплаты</h1>
      <p>Пожалуйста, подождите, пока мы обрабатываем ваш платеж...</p>
    </div>
  );
}

export default PaymentSuccess;
