import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import BattleTeam from "./BattleTeam";
import EnemyBattleTeam from "./EnemyBattleTeam";
import PveBattle from "./PveBattle";
import BattleResultModal from "./BattleResultModal";
import "./PveLevel.css";

const PveLevel = () => {
  const { level } = useParams();
  const navigate = useNavigate(); // Используем useNavigate для перехода между уровнями
  const [cards, setCards] = useState([]);
  const [battleTeam, setBattleTeam] = useState(Array(5).fill(null));
  const [pveTeam, setPveTeam] = useState([]);
  const [showBattle, setShowBattle] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [playerWon, setPlayerWon] = useState(false);
  const [initialBattleTeam, setInitialBattleTeam] = useState([]);
  const [initialPveTeam, setInitialPveTeam] = useState([]);

  const loadBattleTeam = async (cards) => {
    const userId = window.Telegram.WebApp.initDataUnsafe.user.id;
    try {
      const response = await fetch(`/api/user/${userId}/battle_team`);
      const data = await response.json();
      const team = Array(5).fill(null);
      data.forEach((slot) => {
        const card = cards.find((c) => c.player_card_id === slot.player_card_id);
        if (card) {
          team[slot.slot] = { ...card }; // Сохраняем копию карты с исходными значениями HP
        }
      });
      setBattleTeam(team);
      setInitialBattleTeam(JSON.parse(JSON.stringify(team))); // Сохраняем начальное состояние команды
    } catch (error) {
      console.error("Ошибка загрузки боевого отряда:", error);
    }
  };

  useEffect(() => {
    const loadPlayerCards = async () => {
      const userId = window.Telegram.WebApp.initDataUnsafe.user.id;
      try {
        const response = await fetch(`/api/user/${userId}/cards`);
        const data = await response.json();
        setCards(data);
      } catch (error) {
        console.error("Ошибка загрузки карт игрока:", error);
      }
    };

    loadPlayerCards();
    loadPveTeam();
  }, [level]);

  useEffect(() => {
    if (cards.length > 0) {
      loadBattleTeam(cards);
    }
  }, [cards]);

  const loadPveTeam = async () => {
    try {
      const response = await fetch(`/api/pve_level/${level}`);
      const data = await response.json();
      const team = data.map((card) => ({ ...card })); // Сохраняем копию карты с исходными значениями HP
      setPveTeam(team);
      setInitialPveTeam(JSON.parse(JSON.stringify(team))); // Сохраняем начальное состояние команды
    } catch (error) {
      console.error("Ошибка загрузки команды противника:", error);
    }
  };

  const updateBattleTeam = async (newTeam) => {
    const userId = window.Telegram.WebApp.initDataUnsafe.user.id;
    try {
      const payload = newTeam.map((card, index) => ({
        slot: index,
        player_card_id: card ? card.player_card_id : null,
      }));
      await fetch(`/api/user/${userId}/battle_team`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ battleTeam: payload }),
      });
    } catch (error) {
      console.error("Ошибка обновления боевого отряда:", error);
    }
  };

  const startBattle = () => {
    setShowBattle(true);
  };

  const handleBattleEnd = async (playerWon) => {
    setShowBattle(false);
    setPlayerWon(playerWon);
    setShowResultModal(true); // Показываем модальное окно с результатом

    if (playerWon) {
      await updatePlayerLevel(level);
      navigate(`/pve/${parseInt(level) + 1}`); // Переход на следующий уровень
    }
  };

  const updatePlayerLevel = async (completedLevel) => {
    const userId = window.Telegram.WebApp.initDataUnsafe.user.id;
    try {
      const response = await fetch(`/load_user_profile?user_id=${userId}`);
      const data = await response.json();
      const currentLevel = data.level || 1;

      if (completedLevel >= currentLevel) {
        // Обновляем уровень пользователя на следующий, если он прошел текущий уровень
        await fetch(`/update_user_level`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: userId,
            level: parseInt(completedLevel) + 1, // Обновляем уровень на следующий
          }),
        });
      }
    } catch (error) {
      console.error("Ошибка обновления уровня игрока:", error);
    }
  };

  const closeResultModal = () => {
    setShowResultModal(false);
    setBattleTeam(JSON.parse(JSON.stringify(initialBattleTeam))); // Восстанавливаем исходное состояние команды игрока
    setPveTeam(JSON.parse(JSON.stringify(initialPveTeam))); // Восстанавливаем исходное состояние команды ИИ
  };

  return (
    <div className="pve-level-container">
      {!showBattle ? (
        <>
          <h1>PVE Level {level}</h1>
          <div className="pve-opponent-team">
            <h2>Противник</h2>
            <EnemyBattleTeam battleTeam={pveTeam} cards={pveTeam} />
          </div>
          <button className="start-battle-button" onClick={startBattle}>
            Начать бой
          </button>
          <div className="pve-player-team">
            <h2>Ваш отряд</h2>
            <BattleTeam
              battleTeam={battleTeam}
              setBattleTeam={(newTeam) => {
                setBattleTeam(newTeam);
                updateBattleTeam(newTeam);
              }}
              cards={cards}
              onSlotClick={() => {}}
              pveMode
            />
          </div>
        </>
      ) : (
        <PveBattle
          battleTeam={battleTeam}
          setBattleTeam={setBattleTeam}
          pveTeam={pveTeam}
          setPveTeam={setPveTeam}
          onBattleEnd={handleBattleEnd}
        />
      )}
      {showResultModal && (
        <BattleResultModal
          playerWon={playerWon}
          battleTeam={battleTeam}
          onClose={closeResultModal}
        />
      )}
    </div>
  );
};

export default PveLevel;
