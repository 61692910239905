// src/components/Shop.js
import React, { useState } from 'react';
import { GiCrystalGrowth } from 'react-icons/gi';
import NewCardModal from './NewCardModal';
import PaymentForm from './PaymentForm';
import './Shop.css';

function Shop({ show, onClose, userScore, userId, loadScore, loadPlayerCards }) {
  const [activeCategory, setActiveCategory] = useState('cards');
  const [newCard, setNewCard] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaymentFormVisible, setIsPaymentFormVisible] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [paymentDescription, setPaymentDescription] = useState('');
  const [paymentId, setPaymentId] = useState(null);

  const boxes = [
    { id: 1, name: 'Бокс 1-3*', price: 100, description: 'Карточка от 1 до 3 звезд', icon: '📦' },
    { id: 2, name: 'Бокс 2-4*', price: 300, description: 'Карточка от 2 до 4 звезд', icon: '🎁' },
    { id: 3, name: 'Бокс 3-5*', price: 500, description: 'Карточка от 3 до 5 звезд', icon: '🛍️' },
  ];

  const resources = [
    { id: 1, name: 'Энергия', price: 50, description: 'Пополнить запас энергии на 50 единиц', icon: '⚡' },
    { id: 2, name: 'Топливо', price: 30, description: 'Пополнить запас топлива на 30 единиц', icon: '⛽' },
  ];

  const crystals = [
    { id: 1, name: '10,000 кристаллов', price: 100, description: 'Покупка за 100 рублей', icon: <GiCrystalGrowth /> },
    { id: 2, name: '50,000 кристаллов', price: 450, description: 'Покупка за 450 рублей', icon: <GiCrystalGrowth /> },
    { id: 3, name: '100,000 кристаллов', price: 800, description: 'Покупка за 800 рублей', icon: <GiCrystalGrowth /> },
    { id: 4, name: '300,000 кристаллов', price: 2000, description: 'Покупка за 2000 рублей', icon: <GiCrystalGrowth /> },
  ];

  const handleBoxPurchase = async (box) => {
    if (userScore < box.price) {
      alert('Недостаточно очков для покупки!');
      return;
    }

    try {
      const response = await fetch(`/api/user/${userId}/buy_box`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ boxType: box.id })
      });

      const data = await response.json();

      if (data.success) {
        loadScore(userId);
        loadPlayerCards(userId);
        setNewCard(data.card);
        setIsModalOpen(true);
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.error('Ошибка при покупке бокса:', error);
      alert('Произошла ошибка при покупке бокса. Пожалуйста, попробуйте позже.');
    }
  };

  const handleResourcePurchase = async (resource) => {
    if (userScore < resource.price) {
      alert('Недостаточно очков для покупки!');
      return;
    }

    try {
      const response = await fetch(`/api/user/${userId}/buy_resource`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ resourceType: resource.id })
      });

      const data = await response.json();

      if (data.success) {
        loadScore(userId);
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.error('Ошибка при покупке ресурса:', error);
      alert('Произошла ошибка при покупке ресурса. Пожалуйста, попробуйте позже.');
    }
  };

  const handleCrystalPurchase = (crystal) => {
    setPaymentAmount(crystal.price);
    setPaymentDescription(`Покупка ${crystal.name}`);
    setIsPaymentFormVisible(true);
  };

  const handlePaymentSuccess = async (paymentId) => {
    setIsPaymentFormVisible(false);
    alert('Платёж успешно завершён!');

    try {
      const response = await fetch('/api/payment_success', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId, paymentId }),
      });

      const data = await response.json();

      if (data.success) {
        loadScore(userId);
      } else {
        alert('Ошибка при начислении кристаллов.');
      }
    } catch (error) {
      console.error('Ошибка при обработке успешного платежа:', error);
      alert('Произошла ошибка при обработке платежа. Пожалуйста, свяжитесь с поддержкой.');
    }
  };

  const handlePaymentClose = () => {
    setIsPaymentFormVisible(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setNewCard(null);
  };

  return (
    <div className={`shop-modal ${show ? 'show' : ''}`}>
      <div className="shop-content">
        <span className="close-btn" onClick={onClose}>&times;</span>
        <h2>Магазин</h2>
        <div className="shop-categories">
          <button onClick={() => setActiveCategory('cards')} className={activeCategory === 'cards' ? 'active' : ''}>
            Карточки
          </button>
          <button onClick={() => setActiveCategory('resources')} className={activeCategory === 'resources' ? 'active' : ''}>
            Ресурсы
          </button>
          <button onClick={() => setActiveCategory('crystals')} className={activeCategory === 'crystals' ? 'active' : ''}>
            Кристаллы
          </button>
        </div>

        <div className="items-grid">
          {activeCategory === 'cards' && boxes.map(box => (
            <div className="shop-item" key={box.id}>
              <div className="item-icon">{box.icon}</div>
              <div className="item-name">{box.name}</div>
              <div className="item-description">{box.description}</div>
              <button className="buy-button" onClick={() => handleBoxPurchase(box)}>
                <GiCrystalGrowth className="score-icon" /> {box.price}
              </button>
            </div>
          ))}

          {activeCategory === 'resources' && resources.map(resource => (
            <div className="shop-item" key={resource.id}>
              <div className="item-icon">{resource.icon}</div>
              <div className="item-name">{resource.name}</div>
              <div className="item-description">{resource.description}</div>
              <button className="buy-button" onClick={() => handleResourcePurchase(resource)}>
                <GiCrystalGrowth className="score-icon" /> {resource.price}
              </button>
            </div>
          ))}

          {activeCategory === 'crystals' && crystals.map(crystal => (
            <div className="shop-item" key={crystal.id}>
              <div className="item-icon crystal-icon">{crystal.icon}</div>
              <div className="item-name">{crystal.name}</div>
              <div className="item-description">{crystal.description}</div>
              <button className="buy-button" onClick={() => handleCrystalPurchase(crystal)}>
                {crystal.price} ₽
              </button>
            </div>
          ))}
        </div>

        {isPaymentFormVisible && (
          <PaymentForm
            amount={paymentAmount}
            description={paymentDescription}
            userId={userId}
            onSuccess={handlePaymentSuccess}
            onClose={handlePaymentClose}
          />
        )}

        {isModalOpen && newCard && (
          <NewCardModal card={newCard} onClose={closeModal} />
        )}
      </div>
    </div>
  );
}

export default Shop;
