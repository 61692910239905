import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './TopNav.css';

function TopNav() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeIcon, setActiveIcon] = useState(location.pathname);
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    setActiveIcon(location.pathname);
  }, [location]);

  useEffect(() => {
    const tg = window.Telegram.WebApp;

    const loadNotifications = async () => {
      try {
        const user_id = tg.initDataUnsafe.user.id;

        // Загружаем уведомления с сервера
        const notifications = await fetch(`/load_notifications?user_id=${user_id}`).then((res) => res.json());

        // Фильтруем только непрочитанные уведомления
        const unreadNotifications = notifications.filter((notif) => !notif.is_read);

        setNotificationCount(unreadNotifications.length);
      } catch (error) {
        console.error('Ошибка загрузки уведомлений:', error);
      }
    };

    loadNotifications();
  }, []);

  const handleIconClick = (path) => {
    navigate(path);
    vibrate();

    // Отмечаем уведомления как прочитанные при переходе на страницу уведомлений
    if (path === '/notifications') {
      const tg = window.Telegram.WebApp;
      const user_id = tg.initDataUnsafe.user.id;

      fetch('/mark_notifications_as_read', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_id }),
      }).then(() => {
        setNotificationCount(0);
      });
    }
  };

  const vibrate = () => {
    if ('vibrate' in navigator) {
      navigator.vibrate(30); // Короткий виброотклик на 30 мс
    }
  };

  return (
    <div id="topNav">
      <img
        id="dialogIcon1"
        src="img/chat1.png"
        alt="Dialog 1"
        className={activeIcon === '/chat' ? 'active' : ''}
        onClick={() => handleIconClick('/chat')}
      />
      <img
        id="dialogIcon2"
        src="img/messages1.png"
        alt="Dialog 2"
        className={activeIcon === '/dialogs' ? 'active' : ''}
        onClick={() => handleIconClick('/dialogs')}
      />
      <img
        id="dialogIcon3"
        src="img/home.png"
        alt="Dialog 3"
        className={activeIcon === '/global' ? 'active' : ''}
        onClick={() => handleIconClick('/global')}
      />
      <div id="notificationIconWrapper">
        <img
          id="dialogIcon4"
          src="img/notifications1.png"
          alt="Dialog 4"
          className={activeIcon === '/notifications' ? 'active' : ''}
          onClick={() => handleIconClick('/notifications')}
        />
        {notificationCount > 0 && (
          <div className="notification-count">{notificationCount}</div>
        )}
      </div>
    </div>
  );
}

export default TopNav;
