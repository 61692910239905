import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Chat.css';
import StickyNav from './StickyNav';
import TopNav from './TopNav';

function Chat() {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const socketRef = useRef(null);
  const tg = window.Telegram.WebApp;
  const chatMessagesRef = useRef(null);
  const profileCache = useRef({});
  const navigate = useNavigate();

  useEffect(() => {
    tg.ready();
    tg.expand();
    loadMessages();

    const socket = new WebSocket('wss://insectwar.ru');
    socketRef.current = socket;

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === 'new_message') {
        addMessageToChat(message.data);
      }
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    socket.onclose = () => {
      console.log('WebSocket connection closed');
    };

    ensureDocumentIsScrollable();
    preventCollapse();
    document.addEventListener('touchstart', preventCollapse);

    document.addEventListener('DOMContentLoaded', () => {
      tg.expand();
      tg.onEvent('viewportChanged', () => tg.expand());

      document.body.addEventListener('touchmove', (event) => {
        if (!event.target.closest('#content')) {
          event.preventDefault();
        }
      }, { passive: false });
    });

    createStars();

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
      document.removeEventListener('touchstart', preventCollapse);
      document.body.removeEventListener('touchmove', (event) => {
        if (!event.target.closest('#content')) {
          event.preventDefault();
        }
      }, { passive: false });
    };
  }, []);

  useEffect(() => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  }, [messages]);

  const addMessageToChat = (message) => {
    if (message.message.length > 500) {
      message.message = message.message.slice(0, 500) + '...';
    }
    setMessages((prevMessages) => [...prevMessages, message]);
    typeMessage(message);
  };

  const typeMessage = (message) => {
    let currentText = '';
    const messageText = message.message;
    const typeSpeed = 50; // Скорость печатания (мс)
    
    const typingEffect = setInterval(() => {
      if (currentText.length < messageText.length) {
        currentText += messageText.charAt(currentText.length);
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          updatedMessages[updatedMessages.length - 1].message = currentText;
          return updatedMessages;
        });
      } else {
        clearInterval(typingEffect);
      }
    }, typeSpeed);
  };

  const loadMessages = async () => {
    try {
      const response = await fetch('/load_messages');
      const data = await response.json();
      setMessages(data);
    } catch (error) {
      console.error('Ошибка:', error);
    }
  };

  const sendMessage = async () => {
    const user = tg.initDataUnsafe.user;
    const profile = await loadProfile(user.id);
    if (!profile || !message.trim()) {
      console.error('Пользователь не вошел в систему через Telegram или сообщение пустое');
      return;
    }

    const data = {
      type: 'send_message',
      user_id: user.id,
      username: profile.username,
      message: message.trim().slice(0, 500),
    };

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify(data));
      setMessage('');
    } else {
      console.error('WebSocket connection is not open');
    }
  };

  const loadProfile = async (userId) => {
    if (profileCache.current[userId]) {
      return profileCache.current[userId];
    }

    try {
      const response = await fetch(`/load_user_profile?user_id=${userId}`);
      const data = await response.json();
      profileCache.current[userId] = data;
      return data;
    } catch (error) {
      console.error('Ошибка загрузки профиля пользователя:', error);
      return null;
    }
  };

  const showProfile = (userId) => {
    navigate(`/profile?user_id=${userId}&from=chat`);
  };

  const handleSendMessage = () => {
    sendMessage();
  };

  const ensureDocumentIsScrollable = () => {
    const isScrollable = document.documentElement.scrollHeight > window.innerHeight;
    if (!isScrollable) {
      document.documentElement.style.setProperty("height", "calc(100vh + 1px)", "important");
    }
  };

  const preventCollapse = () => {
    if (window.scrollY === 0) {
      window.scrollTo(0, 1);
    }
  };

  const createStars = () => {
    const starfield = document.getElementById('starfield');
    const starCount = 100;

    for (let i = 0; i < starCount; i++) {
      const star = document.createElement('div');
      star.className = 'star';
      star.style.top = `${Math.random() * 100}%`;
      star.style.left = `${Math.random() * 100}%`;
      star.style.animationDelay = `${Math.random() * 5}s`;
      starfield.appendChild(star);
    }
  };

  return (
    <div id="content_chat">
      <div id="starfield"></div>
      <div id="chat" className="expanded">
        <TopNav />
        <div id="chatMessages" className="expanded" ref={chatMessagesRef}>
          {messages.map((msg, index) => (
            <div key={index} className={`message ${msg.user_id === tg.initDataUnsafe.user.id ? 'mine' : 'other'}`}>
              <div className="username" onClick={() => showProfile(msg.user_id)}>
                {msg.user_id === tg.initDataUnsafe.user.id ? 'Вы' : msg.username}
              </div>
              <div className="message-text">{msg.message}</div>
            </div>
          ))}
        </div>
        <div id="chatInput" className="expanded">
          <input
            type="text"
            id="messageInput"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Введите сообщение..."
          />
          <button id="sendMessageButton" onClick={handleSendMessage}>
            Отправить
          </button>
        </div>
      </div>
      <StickyNav />
    </div>
  );
}

export default Chat;
