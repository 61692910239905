import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './StickyNav.css';

function StickyNav() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeIcon, setActiveIcon] = useState(location.pathname);

  useEffect(() => {
    setActiveIcon(location.pathname);
  }, [location]);

  const handleIconClick = (path) => {
    navigate(path);
    vibrate();
  };

  const vibrate = () => {
    if ("vibrate" in navigator) {
      navigator.vibrate(30); // Короткий виброотклик на 30 мс
    }
  };

  return (
    <div className="sticky-nav">
      <img 
        id="navIcon1" 
        src="img/home.png" 
        alt="Icon 1" 
        className={activeIcon === '/' ? 'active' : ''} 
        onClick={() => handleIconClick('/')} 
      />
      <img 
        id="navIcon2" 
        src="img/bee1.png" 
        alt="Icon 2" 
        className={activeIcon === '/bee' ? 'active' : ''} 
        onClick={() => handleIconClick('/bee')} 
      />
      <img 
        id="chatIcon" 
        src="img/chat1.png" 
        alt="Icon 3" 
        className={activeIcon === '/chat' ? 'active' : ''} 
        onClick={() => handleIconClick('/chat')} 
      />
      <img 
        id="navIcon4" 
        src="img/pvemap.png" 
        alt="Icon 4"
        className={activeIcon === '/pvemap' ? 'active' : ''} 
        onClick={() => handleIconClick('/pvemap')} 
      />
      <svg 
        id="navIcon5" 
        viewBox="0 0 24 24" 
        fill="none" 
        stroke="currentColor" 
        strokeWidth="2" 
        strokeLinecap="round" 
        strokeLinejoin="round" 
        className={activeIcon === '/icon5' ? 'active' : ''} 
        onClick={() => handleIconClick('/icon5')} 
        xmlns="http://www.w3.org/2000/svg">
          <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"/>
      </svg>
    </div>
  );
}

export default StickyNav;
