import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './PveMap.css';
import usePreventCollapse from './usePreventCollapse'; // Подключение кастомного хука

const levels = Array.from({ length: 60 }, (_, index) => index + 1); // 60 уровней

const fixedLevelPositions = [
  { x: 10, y: 590 },  // Начинаем с y = 590
  { x: 20, y: 580 },
  { x: 45, y: 570 },
  { x: 90, y: 560 },
  { x: 53, y: 550 },
  { x: 47, y: 540 },
  { x: 15, y: 530 },
  { x: 25, y: 520 },
  { x: 52, y: 510 },
  { x: 88, y: 500 },
  { x: 50, y: 490 },
  { x: 14, y: 480 },
  { x: 86, y: 470 },
  { x: 37, y: 460 },
  { x: 25, y: 450 },
  { x: 55, y: 440 },
  { x: 65, y: 430 },
  { x: 70, y: 420 },
  { x: 10, y: 410 },
  { x: 30, y: 400 },
  { x: 80, y: 390 },
  { x: 80, y: 380 },
  { x: 35, y: 370 },
  { x: 60, y: 360 },
  { x: 40, y: 350 },
  { x: 53, y: 340 },
  { x: 67, y: 330},
  { x: 10, y: 320 },
  { x: 25, y: 310 },
  { x: 45, y: 300 },
  // Новые позиции для уровней с 31 по 60
  { x: 50, y: 290 },  
  { x: 70, y: 270 },
  { x: 85, y: 250 },
  { x: 20, y: 230 },
  { x: 40, y: 210 },
  { x: 60, y: 190 },
  { x: 80, y: 170 },
  { x: 30, y: 150 },
  { x: 55, y: 130 },
  { x: 45, y: 110 },
  { x: 95, y: 190 },
  { x: 45, y: 170 },
  { x: 65, y: 180 },
  { x: 85, y: 170 },
  { x: 35, y: 160 },
  { x: 15, y: 150 },  
  { x: 35, y: 140 },
  { x: 55, y: 130 },
  { x: 75, y: 120 },
  { x: 25, y: 110 },
  { x: 45, y: 100 },
  { x: 65, y: 90 },
  { x: 85, y: 80 },
  { x: 30, y: 70 },
  { x: 50, y: 60 },
  { x: 70, y: 50 },
  { x: 90, y: 40 },
  { x: 40, y: 30 },
  { x: 60, y: 20 },
  { x: 80, y: 10 }
];

const planetSizes = {
  20: 25,  // Радиус 20px
  40: 35,  // Радиус 25px
  60: 30,  // Радиус 30px
  80: 20,  // Радиус 20px
  100: 25, // Радиус 25px
  120: 30  // Радиус 30px
};

const defaultSize = 12.5; // Радиус стандартного круга

const PveMap = () => {
  usePreventCollapse(); // Используем хук для защиты от свайпов и предотвращения сворачивания окна

  const [profile, setProfile] = useState({
    username: 'Имя пользователя',
    profileImage: 'https://via.placeholder.com/100',
    level: 1
  });

  const mapRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    const userId = tg.initDataUnsafe.user.id;
    const username = tg.initDataUnsafe.user.username;

    if (username) {
      setProfile((prevProfile) => ({
        ...prevProfile,
        username: username,
      }));
    }

    loadProfile(userId);
    createStars();
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.scrollTo(0, mapRef.current.scrollHeight); 
    }
  }, []);

  const loadProfile = async (userId) => {
    try {
      const response = await fetch(`/load_user_profile?user_id=${userId}`);
      const data = await response.json();
      setProfile((prevProfile) => ({
        ...prevProfile,
        username: data.username || prevProfile.username,
        profileImage: `${data.profileImage || 'https://via.placeholder.com/100'}?t=${new Date().getTime()}`,
        level: data.level || 1
      }));
    } catch (error) {
      console.error('Ошибка загрузки профиля пользователя:', error);
    }
  };

  const handleLevelClick = (level) => {
    if (level <= profile.level) {
      navigate(`/pve/${level}`);
    } else {
      alert('Этот уровень пока недоступен. Пройдите предыдущие уровни.');
    }
  };

  const handleProfileClick = () => {
    const userId = window.Telegram.WebApp.initDataUnsafe.user.id;
    navigate(`/profile?user_id=${userId}`);
  };

  const convertToPixels = (position) => {
    const vw = window.innerWidth / 100;
    const vh = window.innerHeight / 100;
    return {
      x: position.x * vw,
      y: position.y * vh,
    };
  };

  const getRadius = (level) => {
    if (planetSizes[level]) {
      return planetSizes[level];
    }
    return defaultSize;
  };

  const unlockedPositions = fixedLevelPositions
    .slice(0, profile.level)
    .map((pos, index) => {
      const level = index + 1;
      const radius = getRadius(level);
      const pixelPos = convertToPixels(pos);
      return {
        x: pixelPos.x + radius,
        y: pixelPos.y + radius,
      };
    });

  const solidLinePoints = unlockedPositions.slice(0, -1).map((pos) => {
    return `${pos.x},${pos.y}`;
  }).join(' ');

  const dashedLinePoints = unlockedPositions.slice(-2).map((pos) => {
    return `${pos.x},${pos.y}`;
  }).join(' ');

  const createStars = () => {
    const starfield = document.getElementById('starfield');
    const starCount = 100;

    for (let i = 0; i < starCount; i++) {
      const star = document.createElement('div');
      star.className = 'star';
      star.style.top = `${Math.random() * 100}%`;
      star.style.left = `${Math.random() * 100}%`;
      star.style.animationDelay = `${Math.random() * 5}s`;
      starfield.appendChild(star);
    }
  };

  return (
    <div id="content" ref={mapRef}>
      <div id="starfield"></div>
      <div id="profileContainer" className="profile-container">
        <div id="profileDisplay" className="profile-display" onClick={handleProfileClick}>
          <img id="displayImage" src={profile.profileImage} alt="Фотография профиля" />
          <div className="username" id="displayUsername">{profile.username}</div>
        </div>
      </div>

      {/* Линии */}
      {unlockedPositions.length > 0 && (
        <svg className="map-svg" style={{ height: `600vh` }}> {/* Увеличиваем высоту SVG */}
          <polyline
            className="map-path"
            points={solidLinePoints}
          />
          {dashedLinePoints && (
            <polyline
              className="map-path dashed"
              points={dashedLinePoints}
            />
          )}
        </svg>
      )}

      {/* Уровни */}
      <div className="levels-container">
        {levels.map((level, index) => {
          let extraClass = '';

          if (level % 20 === 0) {
            if (level === 20) extraClass = 'planet planet-20';
            if (level === 40) extraClass = 'planet planet-40';
            if (level === 60) extraClass = 'planet planet-60';
            if (level === 80) extraClass = 'planet planet-80';
            if (level === 100) extraClass = 'planet planet-100';
            if (level === 120) extraClass = 'planet planet-120';
          }

          const radius = getRadius(level);

          return (
            <div
              key={level}
              className={`pve-level ${level > profile.level ? 'locked' : ''} ${level === profile.level ? 'blinking' : ''} ${extraClass}`}
              style={{
                top: `${fixedLevelPositions[index].y}vh`,
                left: `${fixedLevelPositions[index].x}vw`,
                width: `${radius * 2}px`,
                height: `${radius * 2}px`
              }}
              onClick={() => handleLevelClick(level)}
            >
              {level}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PveMap;
