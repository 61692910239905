import React, { useEffect } from 'react';
import './BattleResultModal.css';

const BattleResultModal = ({ playerWon, battleTeam, onClose, level }) => {
  const score = playerWon ? 100 : 0;
  const totalXp = playerWon ? 5 : 0;

  useEffect(() => {
    if (playerWon) {
      updateScore(score);
      updateExperience(battleTeam, totalXp);
      updatePlayerLevel();  // Обновляем уровень после победы
    }
  }, [playerWon]);

  const updateScore = async (scoreIncrement) => {
    const userId = window.Telegram.WebApp.initDataUnsafe.user.id;
    try {
      await fetch(`/api/user/${userId}/update_score`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ scoreIncrement }),
      });
    } catch (error) {
      console.error('Error updating score:', error);
    }
  };

  const updateExperience = async (team, totalXp) => {
    const userId = window.Telegram.WebApp.initDataUnsafe.user.id;
    const aliveCards = team.filter(card => card !== null);
    const xpPerCard = Math.floor(totalXp / aliveCards.length);

    const updatedCards = aliveCards.map(card => ({
      player_card_id: card.player_card_id,
      current_exp: xpPerCard,
    }));

    try {
      await fetch(`/api/user/${userId}/update_cards_experience`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ updatedCards }),
      });
    } catch (error) {
      console.error('Error updating experience:', error);
    }
  };

  const updatePlayerLevel = async () => {
    const userId = window.Telegram.WebApp.initDataUnsafe.user.id;
    try {
      const response = await fetch(`/load_user_profile?user_id=${userId}`);
      const data = await response.json();
      const currentLevel = data.level || 1;

      if (level >= currentLevel) {
        // Обновляем уровень пользователя на следующий, если он прошел текущий уровень
        await fetch(`/update_user_level`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: userId,
            level: parseInt(level) + 1, // Обновляем уровень на следующий
          }),
        });
      }
    } catch (error) {
      console.error("Ошибка обновления уровня игрока:", error);
    }
  };

  return (
    <div className="battle-result-modal">
      <div className="battle-result-content">
        <h2>{playerWon ? 'Поздравляем! Вы выиграли!' : 'Попробуй ещё раз!'}</h2>
        {playerWon && (
          <p>Вы получили {score} очков и {totalXp} XP!</p>
        )}
        <button className="ok-button" onClick={onClose}>OK</button>
      </div>
    </div>
  );
};

export default BattleResultModal;
